<template>
  <div v-if="ready"><q-pull-to-refresh :handler="channelInit" refresh-icon="ion-sync" v-bind:release-message="$t('PTR.RELEASE')" v-bind:refresh-message="$t('PTR.REFRESH')" v-bind:pull-message="$t('PTR.PULL')" color="faded"
    :style="kioskMode === true ? 'margin-bottom: -100px; overflow: hidden;' : 'overflow: hidden;'">

  <div class="product column items-center">
    <q-scroll-observable @scroll="scrolled"/>

    <q-card class="q-card-flat text-center">
      <q-card-media v-if="kioskMode === false" @click.native="galleryShow = getProductPhotoResource(product.data.media.photos.resources)" class="q-card-flat-photo q-card-media-gallery" :class="{ 'q-card-media-gallery-expand': galleryShow }"
        :style="showImages ? `background-image: url(${getProductPhotoResource(product.data.media.photos.resources, galleryShowIndex)})` : 'min-height: 0; max-height: 0; height: 0;'">
        <img v-if="!getProductPhotoResource(product.data.media.photos.resources)" style="height: 111px; background-color: #9B9B9B" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" class="no-photos">
          <q-carousel color="white" infinite thumbnails-icon="ion-images" arrows class="full-width q-card-media-gallery-carousel"
          :class="{ 'q-card-media-gallery-carousel-min': !galleryShow, 'q-card-media-gallery-carousel-max': galleryShow }"
          :autoplay="!galleryShow"
          handle-arrow-keys @slide="galleryShowEventSlide">
            <q-carousel-slide :key="`q-c-${i}`" v-for="(r, i) in product.data.media.photos.resources" :img-src="getProductPhotoResource(product.data.media.photos.resources, i)"/>
            <q-carousel-control slot="control-button" slot-scope="carousel" position="top-right" :offset="[18, 22]">
              <q-btn round dense color="tertiary" icon="ion-close" @click.stop.prevent="galleryShow = false"/>
            </q-carousel-control>
            <q-carousel-control slot="control-progress" slot-scope="carousel" position="bottom" :offset="[0, 0]">
              <q-progress :percentage="carousel.percentage" color="primary"/>
            </q-carousel-control>
          </q-carousel>
      </q-card-media>
      <q-card-media v-else style="margin-top: 100px; margin-bottom: -40px;"></q-card-media>
      <q-card-title>
        <!--
        "logo": {
            "url": "https://res.cloudinary.com/letsbutterfly/image/upload/v1687863290/wings-app/features/e664eb2afc1e9cccf0f13c05444b326d.media_logo.png",
            "shape": "circle",
            "ratio": "normal",
            "padding": false,
            "bg": "#ffffff"
        },
        -->
        <img v-if="product.data.media.logo" :src="product.data.media.logo.url" class="block" style="
          height: 75px;
          border: 2px solid black;
          border-radius: 66px;
          overflow: hidden;
          background-color: white;
          padding: 2px;
          margin: -75px auto 5px;
          position: relative;
          box-shadow: 0 0 1px 3px rgb(255 255 255 / 60%);
          " :style="{
            'width': (product.data.media.logo.ratio === 'wide') ? '120px' : '75px',
            'border-radius': (product.data.media.logo.shape === 'square') ? '4px' : '66px'
          }"
          >
        <img v-if="productName && productName === 'Avalon Marlborough'"
          src="https://www.avaloncommunities.com/pf/resources/img/brand-logos/avalon.svg?d=79"
          class="block" style="
          width: 75px;
          height: 75px;
          border: 2px solid black;
          border-radius: 4px;
          overflow: hidden;
          background-color: black;
          padding: 4px;
          margin: -75px auto 5px;
          position: relative;
          box-shadow: 0 0 1px 3px rgb(255 255 255 / 60%);
        ">
        <img v-if="productName && productName === 'Zaroob Motor City'"
          src="https://eatopi-content.kitopiconnect.com/images/logos/3ed2d6db-b6bd-47bd-9607-a8b7fbc1c009/header/9xtonkii3qf"
          class="block" style="
          width: 75px;
          height: 75px;
          border: 2px solid black;
          border-radius: 4px;
          overflow: hidden;
          background-color: white;
          padding: 4px;
          margin: -75px auto 5px;
          position: relative;
          box-shadow: 0 0 1px 3px rgb(255 255 255 / 60%);
        ">
        <img v-if="productName && productName === 'Talia Apartments'"
          src="https://www.talia-apts.com/uploads/properties/logos/639x639G/17338/white-logo_talia.png?1602865205"
          class="block" style="
          width: 75px;
          height: 75px;
          border: 2px solid black;
          border-radius: 4px;
          overflow: hidden;
          background-color: black;
          padding: 4px;
          margin: -75px auto 5px;
          position: relative;
          box-shadow: 0 0 1px 3px rgb(255 255 255 / 60%);
        ">
        <img v-if="productName && productName === 'Starbucks'"
          src="https://upload.wikimedia.org/wikipedia/en/thumb/d/d3/Starbucks_Corporation_Logo_2011.svg/1920px-Starbucks_Corporation_Logo_2011.svg.png"
          class="block" style="
          width: 75px;
          height: 75px;
          border: 2px solid black;
          border-radius: 66px;
          overflow: hidden;
          background-color: white;
          padding: 2px;
          margin: -75px auto 5px;
          position: relative;
          box-shadow: 0 0 1px 3px rgb(255 255 255 / 60%);
        ">
        <img v-if="productName && productName === 'Allo Beirut - Hessa Street'"
          src="https://cdn-gdhcp.nitrocdn.com/YdLvFIYNyGMsMgiMTKYStcgbqCdztZVD/assets/images/optimized/rev-d9c8d70/wp-content/uploads/2020/12/logo2.png"
          class="block" style="
          width: 75px;
          height: 75px;
          border: 2px solid transparent;
          border-radius: 4px;
          overflow: hidden;
          background-color: white;
          padding: 2px;
          margin: -75px auto 5px;
          position: relative;
          box-shadow: 0 0 1px 3px rgb(255 255 255 / 60%);
        ">
        <!--
        <img v-if="productBanner || productIcon" class="margin-auto-lr block on-bottom" :class="{'banner-image': productBanner}" :src="productBanner || productIcon" style="max-height:150px">
        -->
        <span :class="{hidden: productNameHidden}" class="font-size-140p text-weight-semibold inline-block" style="line-height: 1.1em">
          {{ productName }} <img v-if="product.verified" src="/statics/_demo/checkmark.fill.seal_wing.svg" width="22" :alt="$t('VERIFIED_' + product.verified_status)" :title="$t('VERIFIED_' + product.verified_status)"/>
        </span>
        <div v-if="!isVenue()" class="block text-family-brand text-subinfo-l capitalize font-size-90p" style="margin-top: -4px">
          <span class="text-weight-semibold uppercase inline-block ellipsis text-system-brand" style="
            line-height: 1em;
            width: 90vw;
            font-size: 0.8em;
          " v-html="product.data.business.address.html"/>
        </div>
        <div v-else class="block text-system-brand font-size-100p text-weight-bold uppercase">
          <q-chip color="shallower" text-color="core" :avatar="venue.logo">{{ venue.name }}</q-chip>
        </div>
      </q-card-title>
    </q-card>

    <!-- <p>Concierge: <span v-if="isConciergeOnline">Online</span><span v-else>Offline</span></p> -->

    <!-- info -->
    <transition v-if="contentInfoShow" appear enter-active-class="animated zoomIn animated-d400" leave-active-class="animated zoomOut animated-d200">
      <div v-if="kioskMode === false" class="row no-wrap justify-center items-center relative-position" style="max-width: 400px; height: 70px; margin-top: -20px; margin-bottom: -10px" key="info">
        <q-btn :loading="(accountInitProcessing || account.isLoggedIn !== false) && (!account || account.subscriptionProcess || account.subscribed === null)" color="white" size="1em" flat rounded style="margin: 0px 10px; min-width:68px;" :class="{ 'bg-primary': account.subscribed !== null && account.subscribed, 'bg-tertiary': !account || (account.isLoggedIn === false) || account.subscriptionProcess || (account.subscribed !== null && !account.subscribed) }"
          @click.native="(account.subscribed !== null && account.subscribed) ? unsubscribe() : subscribe()">
          <span v-if="account.subscribed !== null && account.subscribed" style="white-space:nowrap;margin-top:5px;margin-left: 2px;">
            <img src="/statics/_demo/heart.subscribed_white.svg" width="30px" style="margin-right: 4px">
          </span>
          <span v-else-if="account.subscribed !== null" style="white-space:nowrap;margin-top:5px;margin-left: 2px;">
            <img src="/statics/_demo/heart.subscribe_white.svg" width="30px" style="margin-right: 4px">
          </span>
          <span v-else style="white-space:nowrap;margin-top:5px;margin-left: 2px;">
            <img src="/statics/_demo/heart.subscribe_white.svg" width="30px" style="margin-right: 4px">
          </span>
        </q-btn>
        <q-btn size="1.4em" rounded @click.native="about" style="margin: 0px 5px">
          <img src="/statics/_demo/info_primary.svg" width="34px" style="filter: invert(.5)">
        </q-btn>
        <q-btn v-if="product && product.data && product.data.business.g_url" size="1.4em" rounded style="margin: 0px 5px" @click="openURL(product.data.business.g_url)">
          <img src="/statics/_demo/map.fill.svg" width="34px" style="filter: invert(.5)">
        </q-btn>
        <q-btn v-if="product && product.data && product.data.qrcode_ref" size="1.4em" rounded @click.native="qrcode" style="margin: 0px 5px">
          <img src="/statics/_demo/qrcode_primary.svg" width="34px" style="filter: invert(.5)">
        </q-btn>
        <q-btn v-if="shareSheetSupport()" size="1.4em" rounded @click.native="shareSheet" style="margin: 0px 5px">
          <img src="/statics/_demo/square.and.arrow.up.fill_primary.svg" width="28px" style="filter: invert(.5)">
        </q-btn>
      </div>
    </transition>

    <!-- primary: status-as-text -->
    <q-card inline class="limit-width-420 overflow-hidden q-card-grouped q-card-flat on-top-default">
      <q-card-title class="no-padding">
        <div class="row justify-start items-center">
          <transition mode="out-in" appear enter-active-class="animated slideInUp animated-d400" leave-active-class="animated slideOutDown animated-d200">
            <div key="brb" v-if="channel && channel.orders !== 0 && (product.data.business.hoo[today_day].is24 || (today_time_elapsed >= 0 && today_time_elapsed <= 100) || (today_time_elapsed < 0 && today_time_elapsed > -10))" class="text-family-brand block margin-auto-lr font-size-120p text-weight-bolder text-red">
              Change in Operations
            </div>
            <div key="off" v-else-if="channel && channel.online === 0" class="text-family-brand block margin-auto-lr font-size-120p text-weight-bolder text-red">
              Offline
            </div>
            <div key="good" v-else-if="channel && !product.data.business.hoo[today_day].isClosed && (product.data.business.hoo[today_day].is24 || (today_time_elapsed >= 0 && today_time_elapsed <= 100))" class="text-family-brand block margin-auto-lr font-size-120p text-weight-bolder text-educate">
              Ready
            </div>
            <div key="prepping" v-else-if="(today_time_elapsed < 0 && today_time_elapsed > -10)" class="text-family-brand block margin-auto-lr font-size-120p text-weight-bolder text-attention">
              Getting Ready
            </div>
            <div key="others" v-else class="text-family-brand block margin-auto-lr font-size-120p text-weight-bolder text-shallow">
              Zzz
            </div>
          </transition>
        </div>
      </q-card-title>
    </q-card>

    <!-- primary/secondary: status-as-graphics -->
    <transition appear enter-active-class="animated fadeInUp animated-d800" leave-active-class="animated fadeOutUp animated-d800">
      <q-card key="guardian" inline class="no-border limit-width-420 overflow-hidden q-card-grouped q-card-widget"
        :class="{
          'q-card-widget-guard': !channel || (channel && !channel.online && channel.orders === 0) || (today_time_elapsed < 0 || today_time_elapsed > 100) || product.data.business.hoo[today_day].isClosed,
          'q-card-widget-guard-online': channel && channel.online && (channel.orders === 0) && (!product.data.business.hoo[today_day].isClosed) && (product.data.business.hoo[today_day].is24 || (today_time_elapsed >= 0 && today_time_elapsed < 90)),
          'q-card-widget-guard-warning': channel && (channel.orders !== 0 && !(product.data.business.hoo[today_day].isClosed || (today_time_elapsed < 0 || today_time_elapsed > 100))) || (channel && channel.online && !product.data.business.hoo[today_day].is24 && (!product.data.business.hoo[today_day].isClosed) && ((today_time_elapsed >= 90 && today_time_elapsed <= 100) || (today_time_elapsed < 0 && today_time_elapsed > -10)))
        }"
        ref="product-card-guardian"
        >
        <template v-if="today_day">
          <q-progress v-if="channel === null" stripe indeterminate color="hint" height="8px" style="margin-bottom:-8px"/>
          <q-progress v-else-if="channel === false" stripe :percentage="100" color="hint" height="8px" style="margin-bottom:-8px"/>
          <q-progress v-else-if="channel.online === 0 || channel.orders !== 0" :percentage="100" stripe color="protect" height="8px" style="margin-bottom:-8px"/>
          <q-progress v-else-if="product.data.business.hoo[today_day].is24" stripe indeterminate color="educate" height="8px" style="margin-bottom:-8px"/>
          <q-progress v-else-if="product.data.business.hoo[today_day].isClosed" :percentage="100" stripe color="protect" height="8px" style="margin-bottom:-8px"/>
          <q-progress v-else-if="today_time_elapsed >= 0 && today_time_elapsed < 100" stripe indeterminate :color="(today_time_elapsed >= 90) ? 'attention' : 'green-d'" height="8px" style="margin-bottom:-8px"/>
          <q-progress v-else-if="(today_time_elapsed < 0 && today_time_elapsed > -10)" stripe indeterminate color="attention" height="8px" style="margin-bottom:-8px"/>
          <q-progress v-else :percentage="100" stripe color="protect" height="8px" style="margin-bottom:-8px"/>
        </template>
        <q-card-title>
          <transition mode="out-in" appear enter-active-class="animated fadeIn animated-d400" leave-active-class="animated fadeOut animated-d200">
          <div :key="channel ? `guardian-info-img-${channel.online}-${channel.orders}` : `guardian-info-img-null`" class="row justify-start items-center">
            <div class="left on-left-lg items-center row text-center justify-center" style="width:55px;height:70px;">
              <img v-if="channel === false" src="/statics/_demo/circle.b3line_white.svg" width="48" class="brighten-50 dark-brighten-100">
              <img v-else-if="!channel" src="/statics/_demo/circles4_white.svg" width="48" class="brighten-50 dark-brighten-100">
              <img v-else-if="channel.orders !== 0 || (channel && channel.online && (!product.data.business.hoo[today_day].isClosed) && (today_time_elapsed >= 90 && today_time_elapsed <= 100))" src="/statics/_demo/exclamationmark_white.svg" width="48">
              <img v-else-if="channel && channel.online && (!product.data.business.hoo[today_day].isClosed) && (product.data.business.hoo[today_day].is24 || (today_time_elapsed >= 0 && today_time_elapsed < 90))" src="/statics/_demo/heart_white.svg" width="48">
              <img v-else-if="channel && channel.online && (today_time_elapsed < 0 && today_time_elapsed > -10)" src="/statics/_demo/heart_white.svg" width="48">
              <img v-else-if="channel.online === 0 && (product.data.business.hoo[today_day].is24 || (today_time_elapsed >= 0 && today_time_elapsed <= 100))" src="/statics/_demo/exclamationmark_white.svg" width="48" class="brighten-50 dark-img-brighten-50 dark-img-invert-100">
              <img v-else src="/statics/_demo/xmark_white.svg" height="48" class="brighten-50 dark-img-brighten-50 dark-img-invert-100">
            </div>
            <div :key="channel ? `guardian-info-text-${channel.online}-${channel.orders}` : `guardian-info-text-null`" class="float-left line-height-sm">
              <div class="font-size-140p capitalize">
                <span v-if="channel === null">{{ $t('CONNECTING') }}</span>
                <span v-else-if="channel === false">{{ $t('NEW') }}</span>
                <span v-else-if="(channel.orders !== 0 && (product.data.business.hoo[today_day].is24 || (today_time_elapsed >= 0 && today_time_elapsed <= 100))) || (channel.online === 0 && (product.data.business.hoo[today_day].is24 || (today_time_elapsed >= 0 && today_time_elapsed <= 100)))">
                  <span v-if="channel.orders === 0">Notice</span>
                  <span v-else>{{ getStatusChangeData(channel.orders).label }}</span>
                </span>
                <span v-else-if="product.data.business.hoo[today_day].is24">Open</span>
                <span v-else-if="product.data.business.hoo[today_day].isClosed">Closed</span>
                <span v-else-if="today_time_elapsed < 0 && today_time_elapsed > -10">Opening Soon</span>
                <span v-else-if="today_time_elapsed < 0 || today_time_elapsed > 100">Closed</span>
                <span v-else-if="today_time_elapsed >= 90 && today_time_elapsed <= 100">Closing Soon</span>
                <span v-else>Open</span>
              </div>
              <div v-if="channel === false" slot="subtitle" class="text-weight-regular font-size-100p block on-top-sm">
                {{ $t('EMPTY_COMMUNICATION') }}
              </div>
              <div v-if="channel" slot="subtitle" class="text-weight-regular font-size-100p block on-top-sm">
                <span>
                  <!-- {{ today_day }} &bull; -->
                  <template v-if="today_day">
                    <template v-if="product.data.business.hoo[today_day].is24">24 Hours</template>
                    <template v-else-if="product.data.business.hoo[today_day].isClosed">Not open today.</template>
                    <template v-else>
                      {{ product.data.business.hoo[today_day].open | t12format }}-{{ product.data.business.hoo[today_day].close | t12format }}
                    </template>
                  </template>
                </span>
                <!-- <span v-else>Check back next time.</span> -->
              </div>
            </div>
          </div>
          </transition>
          <span v-if="channel && channel.online === 1" slot="right" class="absolute text-family-brand text-weight-semibold absolute-top-right on-left-lg on-top-lg">
            <transition mode="out-in" appear enter-active-class="animated400 bounceIn" leave-active-class="animated400 bounceOut">
              <div key="chip-work" v-if="/*!isConciergeOnline &&*/ (!clients.length || channelFetchActiveClientsRequestsCount || channelFetchActiveClientsRequestActive)" class="chip-live chip-live-blue">
                <q-icon color="white" name="ion-code-working"/>
              </div>
              <div key="chip-live" v-else-if="isConciergeOnline" class="chip-live">LIVE</div>
              <!-- <div key="chip-offl" v-else class="chip-offline">AWAY</div> -->
            </transition>
            <!-- <transition mode="out-in" appear enter-active-class="animated bounceIn animated-d800" leave-active-class="animated bounceOut animated-d800">
              <span v-if="isConciergeOnline" class="chip-live chip-live-blue">CONCIERGE</span>
              <span v-else class="chip-offline">AWAY</span> -->
              <!-- <span v-if="channel && channel.online && (!product.data.business.hoo[today_day].isClosed) && (product.data.business.hoo[today_day].is24 || (today_time_elapsed >= 0 && today_time_elapsed < 90))" class="chip-live">LIVE</span> -->
              <!-- <span v-else-if="channel && channel.online === false" class="hidden text-subinfo-l">3:22 hours ago</span> -->
            <!-- </transition> -->
          </span>
        </q-card-title>
      </q-card>
    </transition>

    <p v-if="product.data.business.hoo[today_day].isClosed || today_time_elapsed < 0 || today_time_elapsed > 100"
      class="text-center on-top-xl text-weight-semibold text-shallow capitalize">
      {{ $t('COMMUNICATION.LAST') }}
    </p>

    <!-- Channel -->
    <template v-if="channel !== null && channel !== false">
      <!-- Loyalty -->
      <div v-if="kioskMode === false" class="items-center margin-auto-lr row justify-around">
        <transition appear enter-active-class="animated fadeInUp animated-d800" leave-active-class="animated fadeOutDown animated-d800">
        <div v-if="channel && channel.loyalty_card" class="on-top-xl on-bottom full-width text-center text-tertiary text-system-brand text-weight-bold" style="
        font-size: 24px;">Loyalty Card</div>
      </transition>
      <transition appear enter-active-class="animated fadeInUp animated-d800" leave-active-class="animated fadeOut animated-d800">
      <q-card v-if="channel && channel.loyalty_card" inline class="limit-width-420 overflow-hidden q-card-grouped q-card-widget q-card-widget-guard q-card-title-columns"
        :ref="`product-card-reward`" v-touch-pan.noMouse="(obj) => { setCardIntent(obj, 'reward', sendLoyaltyRequest) }"
        :disabled="!account.isLoggedIn || ((accountInitProcessing || account.isLoggedIn !== false) && (!account || account.subscriptionProcess || account.subscribed === null))"
        >
        <div
          v-if="accountInitProcessing"
          class="text-system-brand" style="
          position: absolute;
          width: 100%;
          font-size: 35px;
          text-align: center;
          background-color: rgba(0,0,0,0.76);
          z-index: 1;
          font-weight: 700;
          color: #fff;
          padding-top: 62px;
          text-shadow: 0px 0px 10px black;
          height: calc(100% - 52px);
        " :style-disabled="{ 'height': $q.platform.has.touch ? '100%' : 'calc(100% - 52px)' }">
          <q-spinner-puff size="140px" class="margin-auto-lr loading-spinner loading-spinner-gold" style="color: #F4A724; display: block;"/>
        </div>
        <q-card-title>
          <div class="q-card-title-columns-wrapper">
            <div v-if="product.data.business.loyalty && product.data.business.loyalty.image" class="q-card-title-columns-hero" :style="`background-image: url(${product.data.business.loyalty.image}); background-position: center;`"></div>
            <div v-else class="q-card-title-columns-hero" style="background-image: url(https://res.cloudinary.com/letsbutterfly/image/upload/v1690358205/wings-app/features/default.loyalty_image.jpg); background-position: cover"></div>
            <div class="text-center full-width text-weight-semibold text-system-brand">
              <q-chip data-label class="float-right" text-color="subinfo">Card #{{ account.user_payload.modules.loyalty_card.number }}</q-chip>
              <template v-if="account.user_payload.modules.loyalty_card.stamps === product.data.business.loyalty.stamps">
                <!-- free gift -->
                <p class="text-left no-margin"><q-icon class="heartbeat" style="margin: 10px; margin-left: 0;" name="ion-gift" color="value" size="44px"/></p>
                <p class="text-value text-weight-bold line-height-sm text-left">
                  <strong class="text-core">Free Coffee</strong>
                  <br>Congratulations on your reward!
                </p>
                <template v-if="account.user_payload.modules.loyalty_card.carryover">
                  <q-card-separator style="margin-bottom: 20px; margin-top: 20px"/>
                  <p class="text-value text-weight-bold line-height-sm text-left">
                    +{{ account.user_payload.modules.loyalty_card.carryover }} carried over
                  </p>
                  <div class="text-left">
                    <template v-for="i in account.user_payload.modules.loyalty_card.carryover">
                      <span :key="`loyalty-co-${i}`" class="q-chip q-chip-numerator q-chip-info inline-block bg-value-gradient">
                        <q-icon name="ion-ios-gift" color="white" style="margin-left:-2px;margin-top:-5px"/>
                      </span>
                    </template>
                  </div>
                  <q-card-separator style="margin-bottom: 20px; margin-top: 20px"/>
                </template>
                <q-card-separator v-else style="margin-bottom: 20px; margin-top: 50px"/>
                <p class="text-subinfo line-height-xs font-size-80p text-left">Tap to share your PIN and claim your reward.</p>
              </template>
              <template v-else>
                <!-- standard -->
                <p class="text-left no-margin"><q-icon style="margin: 10px; margin-left: 0;" name="ion-gift" color="value" size="44px"/></p>
                <p class="text-value text-weight-bold line-height-sm text-left">
                  <span v-if="account.isLoggedIn === false">Coffee #{{product.data.business.loyalty.stamps + 1}} is on us</span>
                  <strong v-else class="text-core">{{ product.data.business.loyalty.stamps - account.user_payload.modules.loyalty_card.stamps }} more to go</strong>
                </p>
                <div class="text-left">
                <div v-for="i of product.data.business.loyalty.stamps" :key="`loyalty-reward-${i}`" class="inline-block" :id="`loyalty-reward-${i}`">
                  <span v-if="(i > account.user_payload.modules.loyalty_card.stamps)" class="q-chip q-chip-numerator q-chip-info inline-block animate-pop" :style="i > 9 ? 'padding-left:7px !important': ''">
                    {{ i }}
                  </span>
                  <span v-else :class="(i <= account.user_payload.modules.loyalty_card.stamps)?'q-chip-numerator-filled':''" class="q-chip q-chip-numerator q-chip-info inline-block">
                    <img src="/statics/_demo/checkmark.circle.fill.svg"/>
                  </span>
                </div>
                <span class="q-chip q-chip-numerator q-chip-info inline-block bg-core-gradient">
                  <q-icon name="ion-ios-gift" color="white" style="margin-left:-2px;margin-top:-5px"/>
                </span>
                </div>
                <template v-if="account.user_payload.modules.loyalty_card.carryover">
                  <q-card-separator style="margin-bottom: 20px; margin-top: 20px"/>
                  <p class="text-value text-weight-bold line-height-sm text-left">
                    <!-- <strong class="text-core">Free Coffee</strong> -->
                    {{ account.user_payload.modules.loyalty_card.carryover }} free carried over
                  </p>
                  <div class="text-left">
                  <template v-for="(value, index) in Array.from({length: account.user_payload.modules.loyalty_card.carryover}, (_, i) => i + 1)">
                    <span :key="`loyalty-co-${index}`" class="q-chip q-chip-numerator q-chip-info inline-block bg-value-gradient">
                      <q-icon name="ion-ios-gift" color="white" style="margin-left:-2px;margin-top:-5px"/>
                    </span>
                  </template>
                  </div>
                </template>

                <q-card-separator style="margin-bottom: 20px; margin-top: 20px"/>
                <p v-if="!account.isLoggedIn" class="text-subinfo line-height-xs font-size-80p text-left">Login, for free, to acquire this loyalty card.</p>
                <p v-else class="text-subinfo line-height-xs font-size-80p text-left">Tap to share your card with a PIN.</p>
              </template>
            </div>
          </div>
        </q-card-title>
        <q-card-separator/> <!-- v-show="!$q.platform.has.touch" -->
        <q-card-actions> <!-- v-show="!$q.platform.has.touch" -->
          <q-btn class="full-width" color="primary" flat rounded label="Request Reward" @click="sendLoyaltyRequest"
          :disabled="!account.isLoggedIn || ((accountInitProcessing || account.isLoggedIn !== false) && (!account || account.subscriptionProcess || account.subscribed === null))">
            <q-tooltip :delay="2000" inverted>
              Request Reward
            </q-tooltip>
          </q-btn>
        </q-card-actions>
      </q-card>
      </transition>
      </div>

      <div v-if="product.data.uri === 'caff_cafe-e664eb2afc1e9cccf0f13c05444b326d'" class="full-width layout-padding" style="margin-bottom: -40px">
        <masonry :cols="{default: 3, 900: 2, 660: 1}" :gutter="{ default: 20, 680: 10}">
        <q-list class="text-system-brand text-weight-medium q-list-depth"
          style="max-width: 320px !important; margin: 10px auto !important">
          <q-list-header class="no-margin-top text-weight-bold text-tertiary module-title-size">
            Most Loyal
          </q-list-header>
          <q-item>
            <q-item-side class="text-center" avatar="https://res.cloudinary.com/letsbutterfly/image/upload/v1723729975/wings-app/features/nkduepjz5npfksifm8kl.jpg"/>
            <q-item-main>
              <q-item-tile label>Yehia Akoush</q-item-tile>
              <q-item-tile sublabel>
                <q-icon color="educate" name="ion-leaf"/>
                Saved 47 cards</q-item-tile>
            </q-item-main>
            <q-item-side class="text-center">
              <q-icon color="gold" name="ion-ribbon" size="32px" class="on-left-sm"/>
            </q-item-side>
          </q-item>
        </q-list>
        <q-list class="text-system-brand text-weight-medium q-list-depth"
          style="max-width: 320px !important; margin: 10px auto !important">
          <q-list-header class="no-margin-top text-weight-bold text-tertiary module-title-size">
            Sustainability Report
          </q-list-header>
          <q-item>
            <q-item-side class="text-center">
              <q-icon color="educate" name="ion-leaf" size="32px" class="on-left-sm"/>
            </q-item-side>
            <q-item-main>
              <q-item-tile label>10,162 Stamps</q-item-tile>
              <q-item-tile label>941 Cards</q-item-tile>
              <q-item-tile label>42,108 Updates</q-item-tile>
              <q-item-tile sublabel>Since July 2023</q-item-tile>
            </q-item-main>
          </q-item>
        </q-list>
        </masonry>
      </div>

      <!-- Services -->
      <div
        v-if="channel && product.data.business.services"
        class="full-width layout-padding"
        :class="{
          'disabled-ignore': product.data.business.hoo[today_day].isClosed || today_time_elapsed < 0 || today_time_elapsed > 100
        }"
      >
        <!-- iterate through services -->
        <!-- kioskMode = true -->
        <masonry :cols="{default: 3, 900: 2, 660: 1}" :gutter="{ default: 20, 680: 10}">
          <q-list v-if="settings_dev_presense && kioskMode === false" class="text-system-brand text-weight-medium q-list-depth">
            <q-list-header class="no-margin-top text-weight-bold text-tertiary module-title-size">
              <q-icon :color="amIOnline ? 'educate' : 'protect'" name="ion-information-circle" size="32px" class="on-left-sm"/> Active Clients
            </q-list-header>
            <q-item link tag="label" @click.native="channelFetchActiveClients()">
              <q-item-side class="text-center">
                <q-icon name="ion-repeat" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label>Fetch Clients</q-item-tile>
                <q-item-tile sublabel>Refresh list of users</q-item-tile>
              </q-item-main>
            </q-item>
            <div v-if="clients.length">
              <q-item item v-for="client in clients" :key="client.uuid">
                <q-item-side class="text-center">
                  <q-icon v-if="client.state && client.state.role === 'admin'" name="ion-medal" color="gray" size="33px"/>
                  <!-- <q-icon v-else-if="client.uuid === uuid" name="ion-star" color="gold" size="33px"/> -->
                  <q-icon v-else name="ion-contact" :color="client.uuid === uuid ? 'educate' : 'gray'" size="33px"/>
                </q-item-side>
                <q-item-main>
                  <q-item-tile label>
                    {{ client.state && client.state.role === 'admin' ? 'Admin' : 'User' }}
                    <q-chip v-if="client.uuid === uuid" dense>YOU</q-chip>
                  </q-item-tile>
                  <q-item-tile sublabel><q-chip dense>{{ client.uuid }}</q-chip></q-item-tile>
                </q-item-main>
              </q-item>
            </div>
            <div v-else>
              <p class="text-center">No clients</p>
            </div>
          </q-list>
          <!-- show QR code -->
          <div v-if="qrCodeMode === true" style="max-width: 320px !important; margin: 10px auto !important">
            <h3 style="margin-bottom:-12px" class="text-center font-size-140p text-primary text-weight-bold">SCAN & JOIN</h3>
            <p v-if="channel && channel.loyalty_card && false" class="block text-grey text-weight-regular text-center">Get rewards while staying current.</p>
            <p v-else class="block text-grey text-weight-regular text-center">Subscribe to stay current.</p>
            <q-list class="text-system-brand gradient-bottom text-weight-medium q-list-depth" style="max-width: 320px !important; margin: 10px auto !important" >
              <q-item>
                <q-item-main class="text-center">
                  <img v-if="product.data.qrcode_ref" :src="product.data.qrcode_ref" style="width: 100%; border-radius: 2em">
                  <span v-else>A QR Code was not generated for this channel.</span>
                </q-item-main>
              </q-item>
            </q-list>
          </div>
          <!-- iterate groups (if any) -->
          <template v-if="product.data.business.groups && product.data.business.groups.order && product.data.business.groups.order.length">
            <template v-for="group in productServicesGroupedByGroups">
              <div style="max-width: 320px !important; margin: 10px auto !important">
                <h3 style="margin-bottom:-12px; text-transform: uppercase" class="text-blue-grey-7 text-weight-bold text-center font-size-140p">{{ product.data.business.groups.list[group.id].title }}</h3>
                <p class="block text-grey text-weight-regular text-center">{{ product.data.business.groups.list[group.id].description }}</p>
                <q-list
                  v-key="`group-${group.id}`" gradient-bottom
                  class="text-system-brand text-weight-medium q-list-depth"
                  style="max-width: 320px !important; margin: 10px auto !important"
                  link-no
                >
                  <q-list-header hidden class="text-weight-bold text-emphasis text-left font-size-140p" style="text-transform: capitalize">
                    {{ product.data.business.groups.list[group.id].title }}
                    <span class="block text-grey text-weight-regular"
                    style="margin-top: 5px; padding-bottom: 20px">{{ product.data.business.groups.list[group.id].description }}</span>
                  </q-list-header>
                  <!-- <q-item-separator/> -->
                  <template v-for="service in group.services">
                  <q-item
                    v-key="`group-${group.id}-service-${service.uuid}`"
                    data-click="request(service.uuid)"
                  >
                    <q-item-side class="text-center">
                      <img :src="`/statics/services/${product.data.business.components[service.components[0]].categoryId}.${product.data.business.components[service.components[0]].componentId}.svg`" width="33"
                      :class="{ 'opacity-4': serviceStatus(service.uuid) !== 0}"/>
                      <img v-if="serviceStatus(service.uuid) >= 2" src="statics/_demo/cross-overlay.svg" class="absolute" style="left: 16px; height: 36px;"/>
                      <div class="absolute" style="top: 0px; left: 4px">
                      <q-icon v-if="serviceStatus(service.uuid) === 0" name="ion-checkmark-circle" color="educate" size="22px"/>
                      <q-icon v-else-if="serviceStatus(service.uuid) === 1" name="ion-alert" color="attention" size="22px"/>
                      <q-icon v-else-if="serviceStatus(service.uuid) === 2" name="ion-close-circle" color="protect" size="22px"/>
                      <q-icon v-else-if="serviceStatus(service.uuid) === 3" name="ion-code-working" class="statusFader" color="purple-l2" size="22px"/>
                      <q-icon v-else name="ion-code" color="shallow" size="32px"/>
                      </div>
                    </q-item-side>
                    <q-item-main :class="{ 'opacity-4': serviceStatus(service.uuid) !== 0}">
                      <q-item-tile label class="capitalize text-weight-semibold">
                        <div v-if="service.isNew" class="q-chip q-chip-square q-chip-dense bg-blue text-white inline-block margin-xs-r">NEW</div>
                        {{ service.label ? service.label : Wings.services.list[product.data.business.components[service.components[0]].categoryId][product.data.business.components[service.components[0]].componentId].name }}
                      </q-item-tile>
                      <q-item-tile sublabel v-if="service.components.length === 1 && isCustomDescriptor(product.data.business.components[service.components[0]])">
                        <strong>{{ getComponentDescriptors(product.data.business.components[service.components[0]]).availability[serviceStatus(service.uuid)].label }}</strong><br>
                        {{ getComponentDescriptors(product.data.business.components[service.components[0]]).availability[serviceStatus(service.uuid)].sublabel }}
                      </q-item-tile>
                      <q-item-tile sublabel v-if="service.description && service.description.length">
                        {{ service.description }}
                      </q-item-tile>
                      <!--
                      <q-item-tile sublabel v-if="service.lfa && service.lfa.length" class="hidden">
                        <div class="q-item-sublabel" style="margin-bottom: 5px">
                          <div v-for="lfa in service.lfa" :key="lfa" class="q-chip q-chip-square q-chip-dense bg-blue text-white inline-block margin-xs-r">{{ lfa }}</div>
                        </div>
                      </q-item-tile>
                      -->
                    </q-item-main>
                    <q-item-side right v-if="service.cost || service.cost === 0" :class="{ 'opacity-3': service.cost === 0 }">
                      <span v-if="service.partial">+</span>
                      {{ service.cost | nformat('0,0.00') }} <small>{{ currency.label }}</small>
                    </q-item-side>
                  </q-item>
                  </template>
                </q-list>
              </div>
            </template>
          </template>

          <!-- iterate services -->
          <template v-else v-for="(services, serviceCategory) in productServicesGroupedByCategories">
            <q-list v-for="(servicesByComponent, sindex) in componentsGroupedByComponents(services)"
              :key="`${serviceCategory}-${sindex}-kiosk`"
              gradient-bottom
              class="text-system-brand text-weight-medium q-list-depth"
              style="max-width: 320px !important; margin: 10px auto !important"
            >
              <q-list-header class="text-weight-bold text-grey text-left" v-if="Object.keys(servicesByComponent).length > 1">
                {{ Wings.services.list[product.data.business.components[product.data.business.services[Object.keys(servicesByComponent)[0]].components[0]].categoryId][product.data.business.components[product.data.business.services[Object.keys(servicesByComponent)[0]].components[0]].componentId].name }}
              </q-list-header>
              <template v-for="service in servicesByComponent">
                <q-item item :key="`service-${service.uuid}-kiosk`" :style="Object.keys(servicesByComponent).length === 1 ? 'margin-top: -4px' : ''">
                  <q-item-side class="text-center">
                    <img :src="`/statics/services/${product.data.business.components[service.components[0]].categoryId}.${product.data.business.components[service.components[0]].componentId}.svg`" width="33"
                    :class="{ 'opacity-4': serviceStatus(service.uuid) !== 0}"/>
                    <img v-if="serviceStatus(service.uuid) >= 2" src="statics/_demo/cross-overlay.svg" class="absolute" style="left: 16px; height: 36px;"/>
                    <div class="absolute" style="top: 0px; left: 4px">
                    <q-icon v-if="serviceStatus(service.uuid) === 0" name="ion-checkmark-circle" color="educate" size="22px"/>
                    <q-icon v-else-if="serviceStatus(service.uuid) === 1" name="ion-alert" color="attention" size="22px"/>
                    <q-icon v-else-if="serviceStatus(service.uuid) === 2" name="ion-close-circle" color="protect" size="22px"/>
                    <q-icon v-else-if="serviceStatus(service.uuid) === 3" name="ion-code-working" class="statusFader" color="purple-l2" size="22px"/>
                    <q-icon v-else name="ion-code" color="shallow" size="32px"/>
                    </div>
                  </q-item-side>
                  <!--  -->
                  <q-item-main :class="{ 'opacity-4': serviceStatus(service.uuid) !== 0}">
                    <q-item-tile label class="capitalize text-weight-semibold">
                      {{ service.label ? service.label : Wings.services.list[product.data.business.components[service.components[0]].categoryId][product.data.business.components[service.components[0]].componentId].name }}
                    </q-item-tile>
                    <q-item-tile sublabel v-if="service.components.length === 1 && isCustomDescriptor(product.data.business.components[service.components[0]])">
                      <strong>{{ getComponentDescriptors(product.data.business.components[service.components[0]]).availability[serviceStatus(service.uuid)].label }}</strong><br>
                      {{ getComponentDescriptors(product.data.business.components[service.components[0]]).availability[serviceStatus(service.uuid)].sublabel }}
                    </q-item-tile>
                    <q-item-tile sublabel v-if="service.description && service.description.length">
                      {{ service.description }}
                    </q-item-tile>
                    <!--
                    <q-item-tile sublabel v-if="service.lfa && service.lfa.length" class="hidden">
                      <div class="q-item-sublabel" style="margin-bottom: 5px">
                        <div v-for="lfa in service.lfa" :key="lfa" class="q-chip q-chip-square q-chip-dense bg-blue text-white inline-block margin-xs-r">{{ lfa }}</div>
                      </div>
                    </q-item-tile>
                    -->
                  </q-item-main>
                  <q-item-side right v-if="service.cost || service.cost === 0" :class="{ 'opacity-3': service.cost === 0 }">
                    <span v-if="service.partial">+</span>
                    {{ service.cost | nformat('0,0.00') }} <small>{{ currency.label }}</small>
                  </q-item-side>
                </q-item>
              </template>
            </q-list>
          </template>
        </masonry>

        <!-- channel extra options -->
        <p class="text-center text-grey-5 text-weight-regular" style="padding: 40px 0px 0px 20px">
          <span class="block font-size-60p on-top-xs">
            <q-btn @click="kioskModeToggle" class="inline-block font-size-100p spaced-width" dense style="margin-left: -5px"
              :class="{'text-gray' : this.kioskMode === false, 'text-educate': this.kioskMode === true }">
              <span class="text-system-brand">
                <q-icon :name="(this.kioskMode === true) ? 'ion-eye' : 'ion-eye-off'" size="10"/>
                Kiosk
              </span>
            </q-btn>
            <q-btn @click="qrModeToggle" class="inline-block font-size-100p spaced-width" dense
              :class="{'text-gray' : this.qrCodeMode === false, 'text-educate': this.qrCodeMode === true }">
              <span class="text-system-brand">
                <q-icon :name="(this.qrCodeMode === true) ? 'ion-eye' : 'ion-eye-off'" size="10"/>
                QR Code
              </span>
            </q-btn>
          </span>
        </p>

      </div>
    </template>

    <!--
      MODAL: Service Details
    -->
    <q-modal id="dialogServiceDetails" v-model="dialogServiceDetailsShow" position="bottom" class="appLayer dialog-item" no-refocus>
      <q-modal-layout>
        <q-toolbar slot="header" inverted v-touch-pan.vertical.prevent.stop="modalAdapt" class="cursor-grab">
          <q-toolbar-title>Service Details</q-toolbar-title>
        </q-toolbar>
        <q-toolbar slot="header" inverted class="toolbar-overscroll-shadow">
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="empower-light" text-color="empower" rounded @click="notifyMe()">
                Notify Me
              </q-btn>
            </q-card-main>
          </q-card>
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="primary-light" text-color="primary" rounded @click.native="dialogServiceDetailsShow = false; soundPlay('tap')">
                <img :src="'/statics/_demo/' + (anyDarkmode ? 'chevron.compact.down_white.svg': 'chevron.compact.down_primary.svg')" height="10">
              </q-btn>
            </q-card-main>
          </q-card>
        </q-toolbar>
        <div class="q-list-cards text-family-brand layout-padding no-padding-top text-center row justify-center">
          <q-scroll-observable @scroll="toolbarShadowOnOverscroll"/>
          <p class="block text-center text-weight-semibold text-system-brand">
            <img src="/statics/services/baking.cookies.svg" width="33"/><br>
            Dark Chocoloate
            <br><br>
            Current Status: Not Available
            <q-icon name="ion-close-circle" color="protect" size="33px"/>
          </p>
          <p class="text-system-brand text-left">
            Tap "Notify Me" to be notified (via SMS) when this service becomes available.
            The notification will be sent to <strong>+1 508-847-8747</strong>.
          </p>
          <p class="text-system-brand text-left">
            You will only be notified once the service is back and never again until you request a notifcation again.
          </p>
        </div>
      </q-modal-layout>
    </q-modal>

    <!--
      MODAL: Personalize
    -->
    <q-modal id="dialogPersonalize" v-model="dialogPersonalizeShow" position="bottom" class="appLayer dialog-item" no-refocus>
      <q-modal-layout>
        <q-toolbar slot="header" inverted v-touch-pan.vertical.prevent.stop="modalAdapt" class="cursor-grab">
          <q-toolbar-title>{{ $t('PERSONALIZE.LABEL_TT') }}</q-toolbar-title>
        </q-toolbar>
        <q-toolbar slot="header" inverted class="toolbar-overscroll-shadow">
          <q-card class="full-width q-card-grouped text-center no-margin no-padding no-shadow no-border no-background relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase font-size-120p" color="tertiary-light" text-color="tertiary" rounded @click.native="personalizeList(); soundPlay('sheet_up')">
                <img :src="'/statics/_demo/add.fill_' + (anyDarkmode ? 'white' : 'tertiary') + '.svg'" height="24" class="on-left-sm">
                Add
              </q-btn>
            </q-card-main>
          </q-card>
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="primary-light" text-color="primary" rounded @click.native="dialogPersonalizeShow = false; soundPlay('tap')">
                <img :src="'/statics/_demo/chevron.compact.down_' + (anyDarkmode ? 'white' : 'primary') + '.svg'" height="10">
              </q-btn>
            </q-card-main>
          </q-card>
        </q-toolbar>
        <div class="q-list-cards text-family-brand layout-padding no-padding-top text-center row justify-center">
          <q-scroll-observable @scroll="toolbarShadowOnOverscroll"/>
          <p class="q-title text-subinfo slideInDown on-top-lg" style="padding-bottom:20px;line-height: 1.4em">
            Personalize your experience by adding your lifestyle preferences.
          </p>
          <!-- display consumer personas (if any) -->
          <template v-if="!guardian_personas.count">
            <p class="text-center text-primary text-weight-bold font-size-120p animated800 flipInY animatedtimingCubic cursor-pointer" @click="personalizeList">
              <img :src="'/statics/_demo/state.empty_heart' + (anyDarkmode ? '@dark': '') + '.svg'">
              <br>Add your first personalization
            </p>
          </template>
          <template v-for="(personas, group_id) in guardian_personas.data">
            <q-list v-if="personas.count" :key="group_id" class="card text-family-brand full-width on-top-lg" no-border link>
              <template v-if="personas.count">
                <q-list-header compact-left class="font-size-120p text-secondary text-center">
                  {{ group_id }}
                </q-list-header>
                <template v-for="(payload, persona) in personas.data">
                  <transition :key="['transition', group_id, persona, payload].join('-')" mode="out-in" appear enter-active-class="animated800 fadeIn" leave-active-class="fadeOut animated400">
                  <q-item v-if="payload.status" :key="[group_id, persona, payload].join('-')" link @click.native="personaEdit(persona, payload)">
                    <q-item-side>
                      <img style="width:40px" :src="`/statics/_demo/persona.${offerings.personas.bases[persona].group}.${persona}.svg`"
                      class="brighten-50 dark-img-invert-100 on-left-sm"/>
                    </q-item-side>
                    <q-item-main class="font-size-160p text-weight-semibold">
                      <q-item-tile label class="font-size-100p capitalize">{{ persona.replace(/\_/g, ' ') }}</q-item-tile>
                    </q-item-main>
                    <q-item-side right class="text-center" style="width:80px;height:60px">
                      <transition mode="out-in" appear :enter-active-class="`animated400 ${payload.status === null ? 'fadeIn' : 'flipInY'}`" :leave-active-class="`animated200 ${payload.status === null ? 'fadeOut' : 'flipOutY'}`">
                      <div key="health" v-if="payload.status === 'health'">
                        <img src="/statics/_demo/avoid_protect.svg" style="width: auto; height: auto; max-width: 40px; max-height: 50px;"/>
                        <p class="no-margin text-weight-bold text-protect font-size-80p">AVOID</p>
                      </div>
                      <div key="avoid" v-else-if="payload.status === 'avoid'">
                        <img src="/statics/_demo/nosign_attention.svg" style="width: auto; height: auto; max-width: 40px; max-height: 50px;"/>
                        <p class="no-margin text-weight-bold text-attention font-size-80p">AVOID</p>
                      </div>
                      <div key="good" v-else-if="payload.status === 'good'">
                        <img src="/statics/_demo/heart_educate.svg" style="width: auto; height: auto; max-width: 40px; max-height: 50px;"/>
                        <p class="no-margin text-weight-bold text-educate font-size-80p">WANT</p>
                      </div>
                      <div key="null" v-else>
                        <img width="40" src="/statics/_demo/ellipsis.circle.fill_tertiary.svg" style="width: auto; height: auto; max-width: 40px; max-height: 40px; opacity: 0.5"/>
                        <p class="text-faded no-margin text-weight-bold text-tertiary font-size-80p">NOT SET</p>
                      </div>
                      </transition>
                    </q-item-side>
                  </q-item>
                  </transition>
                </template>
              </template>
            </q-list>
          </template>
        </div>
      </q-modal-layout>
    </q-modal>

    <!--
      MODAL: Send Loyalty Request
    -->
    <q-modal id="dialogLoyalty" v-model="dialogLoyaltyShow" position="bottom" class="appLayer dialog-item" no-refocus>
      <q-modal-layout>
        <q-toolbar slot="header" inverted v-touch-pan.vertical.prevent.stop="modalAdapt" class="cursor-grab">
          <q-toolbar-title>{{ $t('LOYALTY.LABEL_TT') }}</q-toolbar-title>
        </q-toolbar>
        <q-toolbar slot="header" inverted class="toolbar-overscroll-shadow">
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="primary-light" text-color="primary" rounded @click.native="endLoyaltyRequest(); soundPlay('tap')">
                <img :src="'/statics/_demo/chevron.compact.down_' + (anyDarkmode ? 'white' : 'primary') + '.svg'" height="10">
              </q-btn>
            </q-card-main>
          </q-card>
        </q-toolbar>
        <div class="q-list-cards text-family-brand layout-padding no-padding-top text-center row justify-center">
          <q-scroll-observable @scroll="toolbarShadowOnOverscroll"/>
          <p class="q-title layout-padding no-padding text-subinfo slideInDown on-top-lg" style="padding-bottom:20px;line-height: 1.4em">
            <template v-if="true || isConciergeOnline">Present PIN for stamping...</template>
            <template v-else>Waiting for staff to be online...</template>
          </p>
          <template v-if="true || isConciergeOnline">
          <q-list class="card text-family-brand full-width on-top-lg" no-border>
            <q-list-header compact style="line-height: initial" class="no-padding">
              <span class="text-black full-width text-center text-system-brand text-weight-semibold uppercase" style="font-size: 100px;">
                {{ dialogLoyaltyShowPin }}
              </span>
            </q-list-header>
          </q-list>
          <div class="card text-family-brand">
            <q-knob medium v-model="dialogLoyaltyShowTimerPercentage" color="empower" :min="0" :max="100" size="100px" line-width="6" readonly>
              <span class="full-width no-margin text-empower text-family-brand text-weight-bold" style="font-size: 33px; line-height: .8em">
                {{ dialogLoyaltyShowTimer }}
              </span>
              <span class="text-weight-bold uppercase" style="font-size: 11px;margin-top: 4px;margin-bottom: -14px;">{{ $tc('SECONDS.COUNT', dialogLoyaltyShowTimer, { count: dialogLoyaltyShowTimer }) }}</span>
            </q-knob>
          </div>
          </template>
        </div>
      </q-modal-layout>
    </q-modal>

    <!--
      MODAL: Personalize: List
    -->
    <q-modal id="dialogPersonalizeList" v-model="dialogPersonalizeListShow" position="bottom" class="appLayer dialog-item" no-refocus>
      <q-modal-layout>
        <q-toolbar slot="header" inverted v-touch-pan.vertical.prevent.stop="modalAdapt" class="cursor-grab">
          <q-toolbar-title>{{ $t('PERSONALIZE_LIST.LABEL_TT') }}</q-toolbar-title>
        </q-toolbar>
        <q-toolbar slot="header" inverted class="toolbar-overscroll-shadow">
          <q-card v-if="settings_voice" class="full-width q-card-grouped text-center no-margin no-padding no-shadow no-border no-background relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-bolder uppercase font-size-120p" color="tertiary-light" text-color="tertiary" rounded @click.native="personalizeVoice()">
                <img :src="'/statics/_demo/mic_' + (anyDarkmode ? 'white' : 'tertiary') + '.svg'" height="24" class="on-left-sm">
              </q-btn>
            </q-card-main>
          </q-card>
          <q-card class="full-width q-card-grouped text-center no-margin no-padding no-shadow no-border no-background relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="primary-light" text-color="primary" rounded @click.native="personalizeList(); soundPlay('tap')">
                <img :src="'/statics/_demo/chevron.compact.down_' + (anyDarkmode ? 'white' : 'primary') + '.svg'" height="10">
              </q-btn>
            </q-card-main>
          </q-card>
        </q-toolbar>
        <div class="q-list-cards text-family-brand layout-padding no-padding-top text-center row justify-center">
          <q-scroll-observable @scroll="toolbarShadowOnOverscroll"/>
          <q-list v-for="(personas, group_id) in guardian_personas.data" :key="group_id" class="card text-family-brand full-width on-top-lg" no-border link>
            <q-list-header compact-left class="font-size-120p text-secondary text-center">
              {{ group_id }}
            </q-list-header>
            <template>
              <q-item v-for="(payload, persona) in personas.data" :key="[group_id, persona, payload].join('-')" link @click.native="personaEdit(persona, payload)" :class="{ 'opacity-6 text-emphasis': payload.status === null }">
                <q-item-side>
                  <img style="width:40px" :src="`/statics/_demo/persona.${offerings.personas.bases[persona].group}.${persona}.svg`"
                  class="brighten-50 dark-img-invert-100 on-left-sm"/>
                </q-item-side>
                <q-item-main class="font-size-160p text-weight-semibold">
                  <q-item-tile label class="font-size-100p capitalize">{{ persona.replace(/\_/g, ' ') }}</q-item-tile>
                </q-item-main>
                <q-item-side right class="text-center" style="width:80px;height:60px">
                  <transition mode="out-in" appear :enter-active-class="`animated400 ${payload.status === null ? 'fadeIn' : 'flipInY'}`" :leave-active-class="`animated200 ${payload.status === null ? 'fadeOut' : 'flipOutY'}`">
                  <div key="health" v-if="payload.status === 'health'">
                    <img src="/statics/_demo/avoid_protect.svg" style="width: auto; height: auto; max-width: 40px; max-height: 50px;"/>
                    <p class="no-margin text-weight-bold text-protect font-size-80p">AVOID</p>
                  </div>
                  <div key="avoid" v-else-if="payload.status === 'avoid'">
                    <img src="/statics/_demo/nosign_attention.svg" style="width: auto; height: auto; max-width: 40px; max-height: 50px;"/>
                    <p class="no-margin text-weight-bold text-attention font-size-80p">AVOID</p>
                  </div>
                  <div key="good" v-else-if="payload.status === 'good'">
                    <img src="/statics/_demo/heart_educate.svg" style="width: auto; height: auto; max-width: 40px; max-height: 50px;"/>
                    <p class="no-margin text-weight-bold text-educate font-size-80p">WANT</p>
                  </div>
                  <div key="null" v-else>
                    <img width="40" src="/statics/_demo/ellipsis.circle.fill_tertiary.svg" style="width: auto; height: auto; max-width: 40px; max-height: 40px; opacity: 0.5"/>
                    <p class="text-faded no-margin text-weight-bold text-tertiary font-size-80p">NOT SET</p>
                  </div>
                  </transition>
                </q-item-side>
              </q-item>
            </template>
          </q-list>
        </div>
      </q-modal-layout>
    </q-modal>

    <!--
      MODAL: Dropff Scan
    -->
    <q-modal id="dialogDropoffScan" v-if="product.data.channel.online" v-model="dialogDropoffScanShow" class="appLayer dialog-item" position="bottom">
      <q-modal-layout>
        <q-toolbar slot="header" inverted v-touch-pan.vertical.prevent.stop="modalAdapt" class="cursor-grab">
          <q-toolbar-title>{{ $t('WINGLET.SCAN.L') }}</q-toolbar-title>
        </q-toolbar>
        <q-toolbar slot="header" inverted class="toolbar-overscroll-shadow">
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="disabled margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="tertiary-light" text-color="subinfo" rounded @click.native="scanFlashToggle(); soundPlay('tap_disabled')">
                <q-icon size="2em" name="ion-flash"/>
              </q-btn>
            </q-card-main>
          </q-card>
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="disabled margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="tertiary-light" text-color="subinfo" rounded @click.native="scanCameraToggle(); soundPlay('tap_disabled')">
                <q-icon size="2em" name="ion-reverse-camera"/>
              </q-btn>
            </q-card-main>
          </q-card>
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="primary-light" text-color="primary" rounded @click.native="scanOff(); soundPlay('tap'); soundFade('pinging', 0.2, 1);">
                <!-- {{ $t('DONE') }} -->
                <img :src="'/statics/_demo/' + (anyDarkmode ? 'chevron.compact.down_white.svg': 'chevron.compact.down_primary.svg')" height="10">
              </q-btn>
            </q-card-main>
          </q-card>
        </q-toolbar>
        <div class="layout-padding no-padding-top text-center text-family-brand">
          <q-scroll-observable @scroll="toolbarShadowOnOverscroll"/>
          <div id="wingletDecodeContainer" class="overflow-hidden" style="height:50vh; border: 4px solid #3d4042; border-radius: 1em">
            <qrcode-stream v-if="dialogDropoffScanShow && dialogDropoffScanStreamShow" @decode="wingletOnDecode" @init="wingletOnInit" class="scanning"/>
          </div>
        </div>
      </q-modal-layout>
    </q-modal>

    <!--
      MODAL: QR Code
    -->
    <q-modal id="dialogQRCode" v-model="dialogQRCodeShow" position="bottom" class="appLayer dialog-item">
      <q-modal-layout style="background-image: url(/statics/_demo/qrcode_primary.svg)">
        <q-toolbar slot="header" inverted v-touch-pan.vertical.prevent.stop="modalAdapt" class="cursor-grab">
          <q-toolbar-title>{{ $t('QRCODE.LABEL') }}</q-toolbar-title>
        </q-toolbar>
        <q-toolbar slot="header" inverted class="toolbar-overscroll-shadow">
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="primary-light" text-color="primary" rounded @click.native="dialogQRCodeShow = false; soundPlay('tap')">
                <!-- {{ $t('DONE') }} -->
                <img :src="'/statics/_demo/' + (anyDarkmode ? 'chevron.compact.down_white.svg': 'chevron.compact.down_primary.svg')" height="10">
              </q-btn>
            </q-card-main>
          </q-card>
        </q-toolbar>
        <div class="layout-padding no-padding-top text-center">
          <q-scroll-observable @scroll="toolbarShadowOnOverscroll"/>
          <transition appear enter-active-class="animated fadeInUp animated-d800">
          <img v-if="product.data.qrcode_ref" :src="product.data.qrcode_ref" style="width:95%;border-radius:2rem;max-width:53vh">
          <span v-else>A QR Code was not generated for this channel.</span>
          </transition>
          <transition appear enter-active-class="animated fadeInUp animated-d800">
          <p v-if="product.data.qrcode_ref" class="text-family-brand text-weight-semibold text-center font-size-100p text-attention" style="word-break: break-all;margin-bottom:-20px">
            {{ product.data.shortlink.replace('ltsbtrf.ly', 'mywin.gs') }}
            <q-btn flat round icon="ion-link" :title="product.data.shortlink.replace('ltsbtrf.ly', 'mywin.gs')" @click="openURL(product.data.shortlink.replace('ltsbtrf.ly', 'mywin.gs'))"/>
          </p>
          </transition>
        </div>
      </q-modal-layout>
    </q-modal>

    <!--
      MODAL: About
    -->
    <q-modal id="dialogAbout" v-model="dialogAboutShow" position="bottom" class="appLayer dialog-item">
      <q-modal-layout style="background-image: url(/statics/_demo/info.svg)">
        <q-toolbar slot="header" inverted v-touch-pan.vertical.prevent.stop="modalAdapt" class="cursor-grab">
          <q-toolbar-title>{{ $t('DRAWER.ITEM.ABOUT.L') }}</q-toolbar-title>
        </q-toolbar>
        <q-toolbar slot="header" inverted class="toolbar-overscroll-shadow">
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="primary-light" text-color="primary" rounded @click.native="dialogAboutShow = false">
                <!-- {{ $t('DONE') }} -->
                <img :src="'/statics/_demo/' + (anyDarkmode ? 'chevron.compact.down_white.svg': 'chevron.compact.down_primary.svg')" height="10">
              </q-btn>
            </q-card-main>
          </q-card>
        </q-toolbar>
        <div class="layout-padding no-padding-top">
          <q-scroll-observable @scroll="toolbarShadowOnOverscroll"/>

          <q-list class="card text-family-brand full-width on-top-lg" no-border link>
            <template v-if="product.data.business.metas.description">
              <q-list-header compact-left class="font-size-120p text-secondary text-center">
                Description
              </q-list-header>
              <q-item>
                <q-item-main>
                  <p class="text-family-brand q-title layout-padding no-padding-top text-subinfo slideInDown" v-html="product.data.business.metas.description"></p>
                </q-item-main>
              </q-item>
            </template>

            <template v-if="product.data.business.phone || getWebsiteDomain(product.data.business.website)">
              <q-list-header compact-left class="font-size-120p text-secondary text-center">
                Contact
              </q-list-header>
              <q-item v-if="product.data.business.phone" link @click.native="call(product.data.business.phone)">
                <q-item-side>
                  <q-icon name="ion-call" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
                </q-item-side>
                <q-item-main class="font-size-120p text-weight-semibold text-system-brand">
                  <q-item-tile label>{{ product.data.business.phone }}</q-item-tile>
                </q-item-main>
              </q-item>
              <q-item v-if="getWebsiteDomain(product.data.business.website)" link @click.native="openURL(product.data.business.website)">
                <q-item-side>
                  <q-icon name="ion-link" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
                </q-item-side>
                <q-item-main class="text-system-brand">
                  <q-item-tile label class="font-size-120p text-weight-semibold">{{ getWebsiteDomain(product.data.business.website) }}</q-item-tile>
                  <q-item-tile sublabel class="text-fixedwidth-brand">{{ product.data.business.website }}</q-item-tile>
                </q-item-main>
              </q-item>
            </template>
          </q-list>
        </div>
      </q-modal-layout>
    </q-modal>

    <!--
      MODAL: Share
    -->
    <q-modal id="dialogShare" v-model="dialogShareShow" position="bottom" class="appLayer dialog-item">
      <q-modal-layout style="background-image: url(/statics/_demo/square.and.arrow.up.fill_primary.svg)">
        <q-toolbar slot="header" inverted v-touch-pan.vertical.prevent.stop="modalAdapt" class="cursor-grab">
          <q-toolbar-title>{{ $t('SHARE') }}</q-toolbar-title>
        </q-toolbar>
        <q-toolbar slot="header" inverted class="toolbar-overscroll-shadow">
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="primary-light" text-color="primary" rounded @click.native="dialogShareShow = false; soundPlay('tap')">
                <!-- {{ $t('DONE') }} -->
                <img :src="'/statics/_demo/' + (anyDarkmode ? 'chevron.compact.down_white.svg': 'chevron.compact.down_primary.svg')" height="10">
              </q-btn>
            </q-card-main>
          </q-card>
        </q-toolbar>
        <div class="layout-padding no-padding-top">
          <q-scroll-observable @scroll="toolbarShadowOnOverscroll"/>
          <q-list class="text-family-brand" no-border link :dark="anyDarkmode">
            <p class="q-title layout-padding no-padding-top text-subinfo slideInDown" style="padding-bottom:20px">
              Share this channel using any of the following methods and platforms for everyone to view.
            </p>
            <q-item v-if="shareSheetSupport()" item @click.native="shareSheet">
              <q-item-main :label="$t('SHARESHEET.LABEL')" class="font-size-160p text-weight-semibold"/>
              <q-item-side class="text-center on-top-sm">
                <img src="/statics/_demo/rectangle.stack.fill.svg" style="height:33px" :class="{ 'filter-invert-80': anyDarkmode }">
              </q-item-side>
            </q-item>
            <q-item item @click.native="openURL(productFullURI)">
              <q-item-main :label="$t('WEBLINK.LABEL')" class="font-size-160p text-weight-semibold"/>
              <q-item-side class="text-center on-top-sm">
                <img src="/statics/_demo/square.and.arrow.up.fill_primary.svg" style="height:33px">
              </q-item-side>
            </q-item>
            <q-item item @click.native="qrcode">
              <q-item-main :label="$t('QRCODE.LABEL')" class="font-size-160p text-weight-semibold"/>
              <q-item-side class="text-center on-top-sm">
                <img src="/statics/_demo/qrcode_primary.svg" style="height:33px">
              </q-item-side>
            </q-item>
          </q-list>
        </div>
      </q-modal-layout>
    </q-modal>

    <div style="height:40px"></div>
  </div>
  </q-pull-to-refresh>
  <q-page-sticky v-if="kioskMode === false" position="bottom" :offset="[0, 30]">
    <transition appear enter-active-class="animated fadeInUp animated400" leave-active-class="animated fadeOutDown animated400">
      <q-btn v-if="!accountInitProcessing && account && account.isLoggedIn === false" rounded size="lg" v-ripple push color="primary" class="shadow-24 text-weight-bold animated800 animated-c1" style="height: 60px; width: 80vw; max-width: 300px" @click.native="loginToSubscribe()">
        <img src="/statics/_demo/heart.subscribe_white.svg" width="33">
        <span class="on-right-sm text-system-brand">Login to Join</span>
      </q-btn>
    </transition>
  </q-page-sticky>
  <q-page-sticky v-if="kioskMode === false" position="bottom-right" :offset="[20, 30]">
    <transition mode="out-in" appear enter-active-class="animated fadeInUp animated400" leave-active-class="animated fadeOutDown animated400">
      <q-btn :loading="account.subscriptionProcess" v-if="account.subscribed !== null && account.subscribed === false && stickyButtonOrderShow" key="account_subscribe" size="lg" v-ripple round push color="primary" class="shadow-24" @click.native="subscribe()">
        <img src="/statics/_demo/heart.subscribe_white.svg" width="33">
      </q-btn>
    </transition>
  </q-page-sticky>
  </div>
  <div v-else class="flex flex-center" style="height: 100vh">
    <q-spinner-puff size="200px" class="loading-spinner loading-spinner-gold" style="color: #F4A724; display: block;"/>
  </div>
</template>

<style lang="stylus">
@import '../css/channel.product'
</style>

<script>
/* globals Recorder */
import LFooter from 'components/l-footer'
import { axiosLIO } from 'plugins/axios'
import { openURL, scroll, debounce } from 'quasar'
import PubNub from 'pubnub'

// formatting modules
import moment from 'moment'
import nformat from 'vue-filter-number-format'
import VuePhoneNumberInput from 'vue-phone-number-input'

// Wings 2.0
import Wings from '../services/wings-2.js'
import _ from 'lodash'

// QR Code Stream
import { QrcodeStream } from 'vue-qrcode-reader'

// Confetti
import VueConfetti from 'vue-confetti'

// DemoBusiness (v1.0 development)
import DemoBusinessCfg from '../services/demo-business-cfg.js'

export default {
  name: 'PageChannelProduct',
  props: [
    'auth',
    'authenticated',
    'lang',
    'ecosystem',
    'anyDarkmode',
    'wingletDialogTrigger',
    'accountDialogTrigger',
    'soundPlay',
    'modalAdapt',
    'isProduction'
  ],
  meta () {
    return {
      title: ['Wings', this.paramName].join(' · '),
      description: { name: 'description', content: 'Live channel for ' + this.paramName }
    }
  },
  components: {
    QrcodeStream,
    LFooter,
    VuePhoneNumberInput,
    VueConfetti
  },
  filters: {
    nformat: nformat,
    tformat: function (val) {
      return moment(val).format('MMMM Do, YYYY')
    },
    t12format: function (val) {
      return moment(val, 'HH:mm').format('h:mm A')
    },
    dtformat: function (val) {
      return moment(val).format('MM/D/YYYY h:mm A')
    },
    dt12format_EST: function (val) {
      let utcOffset = -(300 + 60 + 60 + 60) / 60
      let currentDateTime = moment(val).utcOffset(utcOffset)
      if (currentDateTime.isDST()) {
        utcOffset += 60
        currentDateTime = moment(val).utcOffset(utcOffset)
      }
      return moment(currentDateTime, 'YYYY-MM-DD HH:mm:ss').format('MMMM Do, YYYY h:mm A')
    }
  },
  data () {
    return {
      uuid: null,
      uri: null,
      pn: null,
      channel: null,
      clients: [],
      channelAdminOnline: null,
      channelOnlineTimer: null,
      channelFetchTimer: null,
      channelFetchActiveClientsRequestsFirstTime: true,
      channelFetchActiveClientsRequestsLastCount: 0,
      channelFetchActiveClientsRequestsCount: 0,
      channelFetchActiveClientsRequestActive: false,
      Wings: Wings,
      ready: false,
      kioskMode: null,
      qrCodeMode: false,
      showImages: true,
      lastScrolledPayload: null,
      buttonShareShow: false,
      contentInfoShow: true,
      galleryShow: false,
      galleryShowIndex: 0,
      accountInitProcessing: false,
      stickyButtonOrderShow: false,
      dialogPerspectiveItem: null,
      dialogPerspectiveShow: false,
      dialogPersonalizeShow: false,
      dialogPersonalizeListShow: false,
      dialogPersonalizeVoiceShow: false,
      dialogPersonalizeVoiceStatus: 0, // 0 - idle, 1 - init, 2 - listening, 3 - processing, 4 - processed
      dialogPersonalizeVoiceText: '',
      dialogProductGroup: null,
      dialogProductItem: null,
      dialogProductShow: false,
      dialogProductSendShow: false,
      dialogProductSendSending: false,
      dialogQueueShow: false,
      dialogQRCodeShow: false,
      dialogShareShow: false,
      dialogAboutShow: false,
      dialogDropoffShow: false,
      dialogAccountShow: false,
      dialogBusinessShow: false,
      dialogDropoffScanShow: false,
      dialogDropoffScanStreamShow: false,
      dialogAccountPhoneNumber: '',
      dialogAccountPhoneNumberProcessing: false,
      dialogAccountPhoneNumberPayload: null,
      dialogServiceDetailsShow: false,
      dialogLoyaltyShow: false,
      dialogLoyaltyShowTimerMax: 60, // seconds
      dialogLoyaltyShowTimer: null,
      dialogLoyaltyShowTimex: null,
      dialogLoyaltyShowTimerPercentage: 100,
      dialogLoyaltyShowUID: null,
      dialogLoyaltyShowPin: null,
      datagroups: Wings.datagroups(this),
      bagUpdated: false,
      // venue
      venue: false,
      // account
      account: {
        email: null,
        metadata: null,
        isLoggedIn: false,
        magicLink: null,
        subscribed: null,
        subscriptionProcess: false,
        user_payload: {
          preferences: {},
          modules: {
            loyalty_card: {
              number: 1,
              stamps: 0,
              limit: 9,
              carryover: 0,
              created: +new Date(),
              updated: null
            },
            reviews: null
          }
        }
      },
      // voice
      voice: {
        context: null,
        recorder: null,
        payload: {
          audio: {
            content: null
          },
          config: {
            encoding: 'LINEAR16',
            // sampleRateHertz: 44100,
            languageCode: 'en-US'
          }
        }
      },
      // guardian
      guardian: {
        business: DemoBusinessCfg.business,
        consumer: {
          personas: {
            shakes: { status: null },
            acai_bowls: { status: null },
            burgers: { status: null },
            bowls: { status: null },
            juices: { status: null },
            shots: { status: null },
            cold: { status: null },
            vegan: { status: 'good' },
            eggs: { status: null },
            dairyfree: { status: null },
            curbside: { status: null },
            vegetarian: { status: 'good' },
            glutenfree: { status: null },
            containsnuts: { status: null },
            poultry: { status: 'good' },
            chicken: { status: null },
            meat: { status: 'good' },
            bison: { status: null },
            fish: { status: 'good' },
            beyond_meat: { status: null },
            avocados: { status: null },
            pineapple: { status: null },
            ahi_tuna: { status: null },
            salmon: { status: null },
            mushroom: { status: null },
            lemon: { status: null },
            ginger: { status: null },
            cayenne: { status: null },
            lowcarb: { status: null },
            lowfat: { status: null },
            turmeric: { status: null },
            apple_cider_vinegar: { status: null },
            orange: { status: null },
            honey: { status: null },
            black_pepper: { status: null }
          }
        }
      },
      // offerings
      offerings: DemoBusinessCfg.offerings,
      // services
      services: DemoBusinessCfg.services,
      // dropoffs
      dropoffs: DemoBusinessCfg.dropoffs,
      // HOO
      HOOOptions: [
        { label: '12:00 AM', value: '0:00' },
        { label: '12:30 AM', value: '0:30' },
        { label: ' 1:00 AM', value: '1:00' },
        { label: ' 1:30 AM', value: '1:30' },
        { label: ' 2:00 AM', value: '2:00' },
        { label: ' 2:30 AM', value: '2:30' },
        { label: ' 3:00 AM', value: '3:00' },
        { label: ' 3:30 AM', value: '3:30' },
        { label: ' 4:00 AM', value: '4:00' },
        { label: ' 4:30 AM', value: '4:30' },
        { label: ' 5:00 AM', value: '5:00' },
        { label: ' 5:30 AM', value: '5:30' },
        { label: ' 6:00 AM', value: '6:00' },
        { label: ' 6:30 AM', value: '6:30' },
        { label: ' 7:00 AM', value: '7:00' },
        { label: ' 7:30 AM', value: '7:30' },
        { label: ' 8:00 AM', value: '8:00' },
        { label: ' 8:30 AM', value: '8:30' },
        { label: ' 9:00 AM', value: '9:00' },
        { label: ' 9:30 AM', value: '9:30' },
        { label: '10:00 AM', value: '10:00' },
        { label: '10:30 AM', value: '10:30' },
        { label: '11:00 AM', value: '11:00' },
        { label: '11:30 AM', value: '11:30' },
        { label: '12:00 PM', value: '12:00' },
        { label: '12:30 PM', value: '12:30' },
        { label: ' 1:00 PM', value: '13:00' },
        { label: ' 1:30 PM', value: '13:30' },
        { label: ' 2:00 PM', value: '14:00' },
        { label: ' 2:30 PM', value: '14:30' },
        { label: ' 3:00 PM', value: '15:00' },
        { label: ' 3:30 PM', value: '15:30' },
        { label: ' 4:00 PM', value: '16:00' },
        { label: ' 4:30 PM', value: '16:30' },
        { label: ' 5:00 PM', value: '17:00' },
        { label: ' 5:30 PM', value: '17:30' },
        { label: ' 6:00 PM', value: '18:00' },
        { label: ' 6:30 PM', value: '18:30' },
        { label: ' 7:00 PM', value: '19:00' },
        { label: ' 7:30 PM', value: '19:30' },
        { label: ' 8:00 PM', value: '20:00' },
        { label: ' 8:30 PM', value: '20:30' },
        { label: ' 9:00 PM', value: '21:00' },
        { label: ' 9:30 PM', value: '21:30' },
        { label: '10:00 PM', value: '22:00' },
        { label: '10:30 PM', value: '22:30' },
        { label: '11:00 PM', value: '23:00' },
        { label: '11:30 PM', value: '23:30' },
        { label: '12:00 AM', value: '24:00' }
      ],
      daysOfTheWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
      clock: 0
    }
  },
  computed: {
    isDemoPage () {
      return this.$route.path.includes('-demo')
    },
    // isProduction () {
    //   return window.location.href.startsWith(this.$store.state.app.production_domain)
    // },
    paramName () {
      return this.$route.params.uri.split('-')[0].replace(/_/g, ' ')
        .trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))
    },
    channelID () {
      return this.uri ? 'pn_' + this.uri : null
    },
    productFriendlyName () {
      return this.product.data.uri.split('-')[0].replace(/_/g, ' ')
        .trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))
    },
    productName () {
      let name = this.productFriendlyName
      try {
        name = this.product.data.business.name
      } catch (e) {}
      return name
    },
    productFullURI () {
      return [document.location.origin, '/channel/', this.product.data.uri].join('')
    },
    productShortURI () {
      return this.product.data.shortlink
    },
    productIcon () {
      // if (this.product && this.product.data && this.product.data.business) {
      //   let website = this.product.data.business.website
      //   let metas = this.product.data.business.metas
      //   website = website ? website.split('?')[0] : null
      //   if (website && metas) {
      //     let icons = []
      //     if (metas['twitter:image']) icons.push(metas['twitter:image'])
      //     if (metas['msapplication-tileimage']) icons.push(metas['msapplication-tileimage'])
      //     if (icons.length) {
      //       let icon = icons[0]
      //       if (icon.indexOf('http') === 0) {
      //         return icon
      //       }
      //       if (website.slice(-1) !== '/') website += '/'
      //       return website + icon
      //     }
      //   }
      // }
      return false
    },
    productNameHidden () {
      return false
    },
    productBanner () {
      return false
      // return '/statics/_demo/noor-cafe-logo.png'
      // return '/statics/_demo/caffe-nero-logo.png'
      // return this.anyDarkmode ? 'https://res.cloudinary.com/letsbutterfly/image/upload/f_auto/v1593720437/wings-app/logos/proteinhouse_marlborough-9c5e7e1eb769d58f9e3a1907d8f84328.logo-dark.png'
      //   : 'https://res.cloudinary.com/letsbutterfly/image/upload/f_auto/v1593720437/wings-app/logos/proteinhouse_marlborough-9c5e7e1eb769d58f9e3a1907d8f84328.logo.png'
    },
    isConciergeOnline () {
      return this.clients.length && this.clients.some(client => client.state && client.state.role === 'admin')
    },
    amIOnline () {
      return this.clients.length && this.clients.some(client => client.uuid === this.uuid)
    },
    productServicesGroupedByGroups () {
      // populate services data
      let groups = []
      for (let groupIndex in this.product.data.business.groups.order) {
        let groupId = this.product.data.business.groups.order[groupIndex]
        if (this.product.data.business.groups.list[groupId].private) continue
        let group = {
          id: groupId,
          services: []
        }
        for (let serviceIndex in this.product.data.business.groups.list[groupId].services) {
          let serviceUUID = this.product.data.business.groups.list[groupId].services[serviceIndex]
          group.services.push(this.product.data.business.services[serviceUUID])
        }
        groups.push(group)
      }
      return groups
    },
    productServicesGroupedByCategories () {
      let categories = {}, customCategoryId = '_'
      let productServices = this.product.data.business.services
      let productComponents = this.product.data.business.components
      if (productServices) {
        for (var [sKey, sVal] of Object.entries(productServices)) {
          let categoryId = (sVal.components.length > 1) ? customCategoryId : productComponents[sVal.components[0]].categoryId
          if (sVal.private) continue
          if (!sVal.lfa) {
            sVal.lfa = ['G', 'D', 'N', 'V']
          }
          if (!categories[categoryId]) categories[categoryId] = {}
          categories[categoryId][sVal.uuid || sKey] = sVal
        }
      }
      return categories
    },
    currencyIndex () {
      return (this.product.data.business.currency >= 0) ? this.product.data.business.currency : Wings.cost.default.currencyIndex
    },
    currency () {
      let currencyIndex = (this.product.data.business.currency >= 0) ? this.product.data.business.currency : Wings.cost.default.currencyIndex
      return Wings.cost.currency.list[currencyIndex]
    },
    HOOOptionsOpen () {
      return this.HOOOptions.slice(0, -1)
    },
    HOOOptionsClose () {
      let options = [], startCollecting = false
      this.HOOOptions.forEach((obj, ix) => {
        if (startCollecting) {
          options.push(obj)
        // } else {
        //   obj.disabled = true
        //   options.push(obj)
        }
        if (obj.value === this.dialogHOOStart) {
          startCollecting = true
        }
      })
      return options
    },
    today_day: {
      get () {
        // assume utc_offset in minutes
        let utcOffset = this.product.data.business.timezone.utc_offset
        const date = new Date(Date.now() + utcOffset * 60 * 1000)
        const dayOfWeek = date.getUTCDay()

        const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
        return daysOfWeek[dayOfWeek]
      }
    },
    today_current_time: {
      get () {
        let utcOffset = this.product.data.business.timezone.utc_offset
        // hardcode DST
        if (utcOffset === -300) {
          utcOffset += 60
        }
        let currentTime = moment().utcOffset(utcOffset / 60) // assume utc_offset in minutes
        // if (currentTime.isDST()) {
        //   utcOffset += 60
        //   currentTime = moment().utcOffset(utcOffset)
        // }
        return moment(currentTime, 'HH:mm:ss').add(this.clock, 'seconds').format('h:mm A')
      }
    },
    today_time_elapsed: {
      get () {
        let utcOffset = this.product.data.business.timezone.utc_offset
        // hardcode DST
        if (utcOffset === -300) {
          utcOffset += 60
        }
        let currentTime = moment().utcOffset(utcOffset / 60) // assume utc_offset in minutes
        // if (currentTime.isDST()) {
        //   utcOffset += 60
        //   currentTime = moment().utcOffset(utcOffset)
        // }
        currentTime = moment(this.today_current_time, 'h:mm A') // .utcOffset(utcOffset, true)
        const openingTime = moment(this.product.data.business.hoo[this.today_day].open, 'HH:mm').utcOffset(utcOffset)
        const closingTime = moment(this.product.data.business.hoo[this.today_day].close, 'HH:mm').utcOffset(utcOffset)
        const businessDayDuration = closingTime.diff(openingTime, 'seconds')
        const timeElapsed = currentTime.diff(openingTime, 'seconds')
        // const timeRemaining = closingTime.diff(currentTime, 'seconds')
        const percentageElapsed = (timeElapsed / businessDayDuration) * 100
        // const percentageRemaining = (timeRemaining / businessDayDuration) * 100
        return percentageElapsed.toFixed(2)
      }
    },
    business_isOperationFully: {
      get () {
        return false
      }
    },
    business_isOperationOpen: {
      get () {
        return false
      }
    },
    business_isOperationNone: {
      get () {
        return false
      }
    },
    business_isOperationUnknown: {
      get () {
        return false
      }
    },
    product: {
      get () {
        console.log('product::GET', this.$store.getters['app/getProducts'].list[0])
        return this.$store.getters['app/getProducts'].list[0]
      },
      set (product) {
        console.log('product::SET', product)
        this.$store.commit('app/updateProductPayload', 0, product)
      }
    },
    intentions: {
      get () {
        return this.$store.state.app.intentions
      },
      set (val) {
        return val
      }
    },
    bag: {
      get () {
        return this.$store.state.app.bag
      },
      set (val) {
        return val
      }
    },
    wallet_amount: {
      get () {
        return this.$store.state.app.wallet.us_dollars.amount
      },
      set (val) {
        this.$store.state.app.wallet.us_dollars.amount = val
        this.$store.commit('app/updateAccountWalletUsDollarsAmount', val)
      }
    },
    settings_dev_presense: {
      get () {
        return this.$store.state.app.preferences.dev_presense.enabled
      }
    },
    settings_voice: {
      get () {
        return this.$store.state.app.preferences.voice.enabled
      },
      set (val) {
        this.$store.state.app.preferences.voice.enabled = val
        this.$store.commit('app/updatePreferencesVoiceState', val)
        this.soundPlay('sheet_drop')
      }
    },
    settings_display_groups_description: {
      get () {
        return this.$store.state.app.preferences.display.groups.description
      },
      set (val) {
        this.$store.state.app.preferences.display.groups.description = val
        this.$store.commit('app/updatePreferencesDisplayGroupsDescription', val)
        this.soundPlay('sheet_drop')
      }
    },
    settings_display_cards_mode: {
      get () {
        return this.$store.state.app.preferences.display.cards.mode
      },
      set (val) {
        this.$store.state.app.preferences.display.cards.mode = val
        this.$store.commit('app/updatePreferencesDisplayCardsMode', val)
        this.soundPlay('sheet_drop')
      }
    },
    settings_display_effects: {
      get () {
        return this.$store.state.app.preferences.display.effects.enabled
      },
      set (val) {
        this.$store.state.app.preferences.display.effects.enabled = val
        this.$store.commit('app/updatePreferencesDisplayEffectsState', val)
        this.soundPlay('sheet_drop')
        if (val) {
          this.enableFilters()
          // this.soundPlay('sheet_drop')
        } else {
          this.disableFilters()
          // this.soundPlay('entry_scrub')
        }
      }
    },
    requests_user () {
      if (!this.channel) return []
      if (this.channel.requests && this.channel.requests.length) {
        if (this.account && this.account.metadata) {
          return this.channel.requests.filter(request => request.user.publicAddress === this.account.metadata.publicAddress)
        }
      }
      return []
    },
    requests_user_count () {
      return this.requests_user.length
    },
    guardian_personas () {
      let _count = 0
      let _personas = {}
      for (let personaGroup in this.guardian.business.personas.groups) {
        for (let personaIndex in this.guardian.business.personas.groups[personaGroup].bases) {
          let personaId = this.guardian.business.personas.groups[personaGroup].bases[personaIndex]
          if (!_personas[personaGroup]) {
            _personas[personaGroup] = {
              count: 0,
              data: {}
            }
          }
          if (this.guardian.consumer.personas[personaId] && this.guardian.consumer.personas[personaId].status !== null) {
            _count++
            _personas[personaGroup].count++
            _personas[personaGroup].data[personaId] = this.guardian.consumer.personas[personaId]
          } else {
            _personas[personaGroup].data[personaId] = { status: null }
          }
        }
      }
      return {
        count: _count,
        data: _personas
      }
    },
    offerings_computed () {
      // offerings:
      let computed = {
        items: this.offerings.items,
        consumer: this.guardian_personas
      }

      // consumer personas
      let targetPersonas = {
        byPersona: [],
        byGroups: {},
        byGHA: {}
      }
      let targetPersonasCount = 0
      for (let g in computed.consumer.data) {
        for (let p in computed.consumer.data[g].data) {
          let d = computed.consumer.data[g].data[p]
          if (d.status !== null) {
            targetPersonasCount++
            // byPersona
            targetPersonas.byPersona.push(p)
            // byGroup
            let byGroup = this.offerings.personas.bases[p].group
            if (!targetPersonas.byGroups[byGroup]) {
              targetPersonas.byGroups[byGroup] = {
                good: [],
                avoid: [],
                health: []
              }
            }
            targetPersonas.byGroups[byGroup][d.status].push(p)
            // byGHA
            if (!targetPersonas.byGHA[d.status]) {
              targetPersonas.byGHA[d.status] = []
            }
            targetPersonas.byGHA[d.status].push(p)
          }
        }
      }
      // console.log(':: targetPersonas :', targetPersonas)

      // detect opposing personas
      let mutateOpposements = (personas) => {
        let opposites = this.guardian.business.personas.opposites
        for (let p in personas) {
          let persona = personas[p]
          for (let op in personas) {
            if (p === op) continue
            let oppPersona = personas[op] ? personas[op] : null
            // console.log(`oppositions: ${persona} <> ${oppPersona}`)
            // oppose only if both personas are explicitly set to "good"
            let oppExplicit = this.guardian.consumer.personas[persona].status === 'good' && this.guardian.consumer.personas[oppPersona].status === 'good'
            // console.log('is [', persona, ' <> ', oppPersona, '] oppExplicit?', oppExplicit)
            // oppExplicit = true
            if (oppExplicit) {
              let oppExists = (persona && opposites[oppPersona] && opposites[oppPersona].indexOf(persona) >= 0) || false
              if (oppExists) {
                let personaSplit0 = personas.filter((c) => c.indexOf(persona) !== 0)
                let personaSplit1 = personas.filter((c) => c.indexOf(oppPersona) !== 0)
                // console.log(`   ✅: MUTATING: ${persona}:${oppPersona}`, personaSplit0, personaSplit1)
                return [mutateOpposements(personaSplit0), mutateOpposements(personaSplit1)]
              }
            }
          }
        }
        // console.log('❤️❤️❤️', personas)
        return personas
      }
      // mutute only "good" personas
      let targetPersonasSplits = mutateOpposements(targetPersonas.byPersona)
      // let targetPersonasSplits = mutateOpposements(targetPersonas.byGHA.good)
      // console.log(':: targetPersonasSplits ::', targetPersonasSplits)
      if (typeof targetPersonasSplits[0] === 'object') {
        targetPersonasSplits = [targetPersonasSplits[0], targetPersonasSplits[1]]
      } else {
        targetPersonasSplits = [targetPersonasSplits]
      }
      // console.log(':: targetPersonasSplits ::', targetPersonasSplits)

      // clean opposing personas
      let isPersonaContainer = (set) => {
        return typeof set === 'object' && set.length && typeof set[0] !== 'object'
      }
      let cleanPersonas = []
      let cleanPersonasSplits = (personas) => {
        // console.log(' >> ', personas)
        if (isPersonaContainer(personas)) {
          cleanPersonas.push(personas)
        } else {
          for (let p in personas) {
            if (isPersonaContainer(personas[p])) {
              cleanPersonas.push(personas[p])
            } else {
              cleanPersonas.push(cleanPersonasSplits(personas[p]))
            }
          }
        }
      }
      cleanPersonasSplits(targetPersonasSplits)
      cleanPersonas = cleanPersonas.filter((c) => c)
      // console.log(':: cleanPersonas ::', cleanPersonas)
      let targetPersonasSplitsRefined = []
      for (let tps in cleanPersonas) {
        // console.log(cleanPersonas[tps].sort())
        targetPersonasSplitsRefined.push(JSON.stringify(cleanPersonas[tps].sort()))
      }
      targetPersonasSplitsRefined = targetPersonasSplitsRefined.filter((v, i, a) => a.indexOf(v) === i)
      // console.log(':: targetPersonasSplitsRefined ::', targetPersonasSplitsRefined)
      for (let tps in targetPersonasSplitsRefined) {
        targetPersonasSplitsRefined[tps] = JSON.parse(targetPersonasSplitsRefined[tps])
        // console.log(targetPersonasSplitsRefined[tps])
      }
      console.log(':: targetPersonasSplitsRefined ::')
      for (let rtp in targetPersonasSplitsRefined) {
        console.log(targetPersonasSplitsRefined[rtp])
      }

      // toggle verboseDebug for console readout (slows performance)
      let verboseDebug = false

      // go through the offerings
      let promotedOffers = {}
      for (let offer in computed.items) {
        let computedOffer = computed.items[offer]
        let promoted = []
        let promotedDefault = false
        let promotionProcessed = false

        // Current offer
        if (verboseDebug) console.log('===============================================')
        if (verboseDebug) console.log(`🟣     :: OFFER: ${offer}`)

        // go through all the potential products for this offering
        for (let p in computedOffer.products) {
          let product = computedOffer.products[p]

          // status of current products' promotion state
          let promotedStatus = null
          // let promotedStatusByGroups = {}

          // go through each persona splits (if any) and analyze each group
          let totalTargetPersonasSplits = targetPersonasSplitsRefined.length
          for (let tpsr in targetPersonasSplitsRefined) {
            // setup the target persona for the current product offering
            let targetPersonasSplit = targetPersonasSplitsRefined[tpsr]
            let targetPersonasSplitMeta = {
              byPersona: [],
              byGroups: {},
              byGHA: {}
            }
            // ... byPersona
            targetPersonasCount = targetPersonasSplit.length
            targetPersonasSplitMeta.byPersona = targetPersonasSplit
            for (let tpsi in targetPersonasSplit) {
              let _persona = targetPersonasSplit[tpsi]
              let consumerPersona = this.guardian.consumer.personas[_persona]
              // ... byGroup
              let byGroup = this.offerings.personas.bases[_persona].group
              if (!targetPersonasSplitMeta.byGroups[byGroup]) {
                targetPersonasSplitMeta.byGroups[byGroup] = {
                  good: [],
                  avoid: [],
                  health: []
                }
              }
              targetPersonasSplitMeta.byGroups[byGroup][consumerPersona.status].push(_persona)
              // ... byGHA
              if (!targetPersonasSplitMeta.byGHA[consumerPersona.status]) {
                targetPersonasSplitMeta.byGHA[consumerPersona.status] = []
              }
              targetPersonasSplitMeta.byGHA[consumerPersona.status].push(_persona)
            }
            //
            if (verboseDebug) console.log('===============================================')
            if (verboseDebug) console.log(`       :: PRODUCT ${offer}•${product.default ? 'default' : 'mutation[' + p + ']'} +testing(_meta: ${(1 * tpsr) + 1} / ${totalTargetPersonasSplits})`)
            if (verboseDebug) console.log(`       :: PRODUCT _meta`, product.personas)
            if (verboseDebug) console.log('       :: _meta.byPersona', targetPersonasSplitMeta.byPersona)
            if (verboseDebug) console.log('       :: _meta.byGroups', targetPersonasSplitMeta.byGroups)
            if (verboseDebug) console.log('       :: _meta.byGHA', targetPersonasSplitMeta.byGHA)
            if (verboseDebug) console.log('       ::======================================')
            //
            //
            // global computes
            //
            let mn = product.nutrition.macronutrients
            let total = mn.p + mn.c + mn.f
            product.nutrition.total = total
            product.nutrition.p_portion = mn.p / total
            product.nutrition.c_portion = mn.c / total
            product.nutrition.f_portion = mn.f / total

            // check if this product is satisfactory against _meta.GOOD
            if (verboseDebug) console.log('       :: CHECK good.*', targetPersonasSplitMeta.byGHA.good)
            if (targetPersonasSplitMeta.byGHA.good) {
              let totalGoods = 0
              for (let g in targetPersonasSplitMeta.byGHA.good) {
                let gg = targetPersonasSplitMeta.byGHA.good[g]
                let ggExists = product.personas.indexOf(gg) >= 0
                if (!ggExists) {
                  // check ingredients
                  let ingredientsMatch = product.contains.filter((c) => c.base === gg)
                  ggExists = ingredientsMatch && ingredientsMatch.length > 0
                }
                if (!product.computes) {
                  product.computes = {}
                }
                if (!ggExists) {
                  if (gg === 'lowcarb') {
                    let carbs = (mn.c / total) * 100
                    if (carbs >= 20 && carbs <= 30) {
                      product.computes.lowcarb = true
                      ggExists = true
                    }
                  } else if (gg === 'lowfat') {
                    let fats = (mn.f / total) * 100
                    if (fats >= 15 && fats <= 20) {
                      product.computes.lowfat = true
                      ggExists = true
                    }
                  }
                }
                if (verboseDebug) console.log(`       :: CHECK good.${gg}`, ggExists)
                if (ggExists) {
                  totalGoods++
                }
              }
              promotedStatus = totalGoods === targetPersonasSplitMeta.byGHA.good.length
            } else {
              if (verboseDebug) console.log(`       :: CHECK good [EMPTY]`)
            }
            // check if this product is satisfactory against _meta.AVOID
            if (verboseDebug) console.log('       :: CHECK avoid.*', targetPersonasSplitMeta.byGHA.avoid)
            if (targetPersonasSplitMeta.byGHA.avoid) {
              let totalAvoids = 0
              for (let a in targetPersonasSplitMeta.byGHA.avoid) {
                let aa = targetPersonasSplitMeta.byGHA.avoid[a]
                // check personas
                let aaExists = product.personas.indexOf(aa) >= 0
                if (!aaExists) {
                  // check ingredients
                  let ingredientsMatch = product.contains.filter((c) => c.base === aa)
                  aaExists = ingredientsMatch && ingredientsMatch.length > 0
                }
                if (!product.computes) {
                  product.computes = {}
                }
                if (!aaExists) {
                  if (aa === 'lowcarb') {
                    let carbs = (mn.c / total) * 100
                    if (carbs >= 20 && carbs <= 30) {
                      product.computes.lowcarb = true
                      aaExists = true
                    }
                  } else if (aa === 'lowfat') {
                    let fats = (mn.f / total) * 100
                    if (fats >= 15 && fats <= 20) {
                      product.computes.lowfat = true
                      aaExists = true
                    }
                  }
                }
                if (verboseDebug) console.log(`       :: CHECK avoid.${aa}`, aaExists)
                if (aaExists) {
                  totalAvoids++
                }
              }
              if (promotedStatus) {
                promotedStatus = promotedStatus && totalAvoids === 0
              }
              // promotedStatus = totalAvoids === 0
            }
            if (verboseDebug) console.log('       :: promotedDefault', promotedDefault)
            if (verboseDebug) console.log('       :: promotedStatus', promotedStatus, promotedStatus ? '✅' : '❌')

            // promote product
            if (promotedStatus && !promotedDefault) {
              promoted.push(product)
            }
            if (product.default && promotedStatus && !promotedDefault) {
              promotedDefault = true
            }
          }
        }

        // finalize based on flow type
        if (computedOffer.flow === 'exact' && !promotionProcessed) {
          if (!promoted.length && !targetPersonasCount) {
            if (!computedOffer.products[0].personas.length) {
              promoted.push(computedOffer.products[0])
            }
          }
        }

        if (promoted.length) {
          promotedOffers[offer] = {
            offer: offer,
            products: promoted
          }
        }
      }

      return promotedOffers
    }
  },
  /*
   * INIT
   */
  created () {
    document.querySelector('#q-app > .q-loading-bar.top.bg-primary').hidden = true

    // set up global URI
    this.uri = this.$route.params.uri

    // check authentication
    this.accountInit()

    // uuid
    this.uuid = localStorage.getItem('uuid')
    if (!this.uuid) {
      this.uuid = this.$guid.noHyphen(this.$guid.generate())
    }
    localStorage.setItem('uuid', this.uuid)

    // init Wings data
    Wings.DFID = {}
    Wings.DGID = {}
    for (let dg in this.datagroups) {
      Wings.DGID[this.datagroups[dg].id.toUpperCase()] = 1 * dg
      for (let df in this.datagroups[dg].datafields) {
        Wings.DFID[this.datagroups[dg].datafields[df].id] = { datagroup: dg, datafield: df }
        Wings.DGID[[this.datagroups[dg].id, this.datagroups[dg].datafields[df].id].join('_').toUpperCase()] = 1 * df
      }
    }

    // process any instructionSet
    this.processIs()

    // connect to PN
    this.channelInit()
  },
  beforeMount () {
    // query product information
    axiosLIO.get('/channel/' + this.uri).then((res) => {
      console.log(res)
      let product = res.data.data.product
      product.data = JSON.parse(product.payload)
      this.$store.commit('app/updateProducts', { list: [product], group: {} })
      setTimeout(() => { this.ready = true }, 200)
      this.datafieldsInit()
      this.manifestInit()
      this.productHooInit()
      this.productLoyaltyInit()
      this.kioskModeInit()
      this.clockInit()
    })
  },
  mounted () {
    document.querySelector('#appHeader').classList.add('no-border')
    document.querySelector('#appHeader').classList.add('no-shadow')
    // detect no presence
    window.addEventListener('beforeunload', this.channelDisconnect)
    // detect change routing
    this.$router.beforeEach((to, from, next) => {
      if (from.matched.some(record => record.path.startsWith('/channel/'))) {
        this.channelDisconnect()
      }
      next()
    })
  },
  beforeDestroy () {
    window.removeEventListener('beforeunload', this.channelDisconnect)
  },
  // watch: {
  //   wingletDialogTrigger () {
  //     this.wingletDialogOpen()
  //   },
  //   accountDialogTrigger () {
  //     setTimeout(() => {
  //       this.soundPlay('sheet_up')
  //       this.dialogAccountShow = true
  //       this.toolbarShadowOnOverscrollClear()
  //     }, 100)
  //   }
  // },
  methods: {
    openURL,
    subscriptionInit () {
      let subscribed = false
      this.accountInitProcessing = false
      this.account.subscriptionProcess = true
      var lts = localStorage.getItem('lts')
      if (lts && lts === this.uri) {
        // we need to subscribe to this
        localStorage.removeItem('lts')
        this.subscribe()
        return
      }

      console.log(':: subscriptionInit()')
      axiosLIO.get('/channels/' + this.uri).then((res) => {
        console.log(res)
        try {
          subscribed = res.data.data.subscribed
        } catch (e) {}
        if (subscribed) {
          console.log(':: ❤️ Subscriber')
          let payloadUser = res.data.data.channel.payload_user
          if (payloadUser) {
            console.log(':: Download payload_user')
            console.log(payloadUser)
            this.account.user_payload = JSON.parse(payloadUser)
          }
        } else {
          console.log(':: ⚠️ Not Subscriber')
        }
        this.account.subscribed = subscribed
        this.account.subscriptionProcess = false
      })
    },
    isVenue () {
      try {
        let placeId = this.product.data.place_id
        let venueId = Wings.venues.relations[placeId]
        if (venueId) {
          this.venue = Wings.venues.list[venueId]
          if (this.venue) {
            return true
          }
        }
      } catch (e) { }
      return false
    },
    getServiceCategoryLabel (serviceCategory) {
      const category = Wings.services.categories.list.find(category => category.value === serviceCategory)
      return category ? category.label : serviceCategory
    },
    componentsGroupedByComponents (services) {
      let categories = {}
      for (var [sKey, sVal] of Object.entries(services)) {
        let componentUUID = sVal.components[0]
        let component = this.product.data.business.components[componentUUID]
        if (!component || component.private) continue
        let componentId = component.componentId
        if (!categories[componentId]) categories[componentId] = {}
        categories[componentId][sKey] = sVal
      }
      return categories
    },
    serviceLastUpdate (serviceUUID) {
      let updated = 0
      let service = this.product.data.business.services[serviceUUID]
      for (let componentIndex in service.components) {
        let componentUUID = service.components[componentIndex]
        let componentData = this.product.data.business.components[componentUUID]
        if (updated < componentData.updated) {
          updated = componentData.updated
        }
      }
      for (let dependentIndex in service.dependencies) {
        let dependentUUID = service.dependencies[dependentIndex]
        let dependentUpdated = this.serviceLastUpdate(dependentUUID)
        if (updated < dependentUpdated) {
          updated = dependentUpdated
        }
      }
      return updated
    },
    serviceStatus (serviceUUID) {
      let status = null, componentsStatus = []
      let service = this.product.data.business.services[serviceUUID]
      // compute components
      for (let componentIndex in service.components) {
        let componentUUID = service.components[componentIndex]
        componentsStatus.push(this.product.data.business.components[componentUUID].status)
      }
      // compute dependencies
      for (let dependencyIndex in service.dependencies) {
        let serviceUUID = service.dependencies[dependencyIndex]
        componentsStatus.push(this.serviceStatus(serviceUUID))
      }
      status = componentsStatus.some(c => c === null) ? null : Math.max(...componentsStatus)
      return status
    },
    isCustomDescriptor (component) {
      return Wings.services.list[component.categoryId][component.componentId].descriptors || false
    },
    getComponentDescriptors (component) {
      return Wings.services.list[component.categoryId][component.componentId].descriptors || Wings.services.defaults.descriptors
    },
    //
    getProductPhotoResource (resources, ix) {
      // sort by height
      // resources.sort((r1, r2) => {
      //   return r1.height - r2.height
      // })
      if (!resources || resources.length === 0) {
        return null
      }
      let resource = resources[ix ? ix : 0]
      // console.log(resources)
      // for (let i in resources) {
      //   let r = resources[i]
      //   let s = (r.height > 315) ? '⚠️' : '👍'
      //   console.log(`${s} ${r.width}×${r.height}`)
      // }
      if (resource.photo_reference) {
        return `https://maps.googleapis.com/maps/api/place/photo?maxwidth=800&photoreference=${resource.photo_reference}&key=AIzaSyB3og1L9DJe2lM7q3HocbVdLM2Q6lDhPZY`
      } else if (resource.photo_source) {
        return resource.photo_source
      }
    },
    galleryShowEventSlide (index, direction) {
      this.galleryShowIndex = index
    },
    loginToSubscribe () {
      // set LTS (Login-To-Subscribe)
      localStorage.setItem('lts', this.uri)
      this.$router.push('/login')
    },
    updateUserPayload () {
      axiosLIO.post('/channel/payload/' + this.uri, {
        payload: JSON.stringify(this.account.user_payload)
      }).then((res) => {
        console.log(res)
      })
    },
    unsubscribe () {
      this.$q.dialog({
        title: 'Unsubscribe',
        message: ['Are you sure you want to unsunscribe from ', this.productName, '? Doing so will remove all your saved settings.'].join(''),
        color: 'primary',
        ok: this.$t('UNSUBSCRIBE'),
        cancel: this.$t('CANCEL')
      }).then(() => {
        // delete
        this.account.subscriptionProcess = true
        axiosLIO.get('/channel/remove/' + this.uri).then((rest) => {
          setTimeout(this.subscriptionInit, 1)
          // reset channel list
          if (this.$store.state.app.channels) {
            this.$store.state.app.channels.list = []
          }
        })
      }).catch(() => {
        // ignore
        this.account.subscriptionProcess = false
      })
    },
    subscribe () {
      if (this.account && this.account.isLoggedIn === false) {
        this.loginToSubscribe()
        return
      }
      this.account.subscriptionProcess = true
      axiosLIO.get('/channel/add/' + this.uri).then((res) => {
        // console.log(res)
        setTimeout(this.subscriptionInit, 200)
        // reset channel list
        if (this.$store.state.app.channels) {
          this.$store.state.app.channels.list = []
        }
        // subscribed
        // this.confetti_stop()
        // setTimeout(this.confetti_start, 200)
        this.$q.notify({
          color: 'white',
          textColor: 'value',
          // detail: 'Subscription',
          message: 'You are now subscribed!',
          position: 'top',
          icon: 'ion-star'
        })
        // this.$q.dialog({
        //   title: 'Subscribe',
        //   message: 'You are now subscribed!',
        //   color: 'primary',
        //   ok: this.$t('OK')
        // })
      })
    },
    productHooInit () {
      if (this.product.data.business.hoo) return
      this.product.data.business.hoo = {}
      for (let i in this.daysOfTheWeek) {
        this.product.data.business.hoo[this.daysOfTheWeek[i]] = {
          open: '8:00',
          close: '23:00',
          is24: false,
          isClosed: false
        }
      }
      // mutate the product
      this.mutateProduct(this.product.id, this.product.data, 'BUSINESS.HOO: INIT', () => {})
    },
    productLoyaltyInit () {
      if (this.product.data.business.loyalty) return
      this.product.data.business.loyalty = {
        stamps: 8, // 8 transactions required
        rewardsMetaOptions: [], // free item
        image: false
      }
      // mutate the product
      this.mutateProduct(this.product.id, this.product.data, 'BUSINESS.LOYALTY: INIT', () => {})
    },
    clockInit () {
      // toggle the clock every minute
      setInterval(() => {
        this.clock = !this.clock
      }, 1000 * 60)
    },
    kioskModeInit () {
      // enabled kiosk mode
      this.kioskMode = 'kiosk' in this.$route.query
      this.qrCodeMode = 'qr' in this.$route.query
      this.kioskModeRefresh()
    },
    kioskModeToggle () {
      this.kioskMode = !this.kioskMode
      this.kioskModeRefresh()
    },
    kioskModeRefresh () {
      document.querySelector('#appHeader').classList[this.kioskMode ? 'add' : 'remove']('hidden')
    },
    qrModeToggle () {
      this.qrCodeMode = !this.qrCodeMode
    },
    getStatusChangeData (status) {
      return {
        1: {
          label: 'Be right back',
          icon: '/statics/_demo/time-closed_brb.svg'
        },
        2: {
          label: 'Closed for the day',
          icon: '/statics/_demo/time-closed.svg'
        },
        3: {
          label: 'Closing early',
          icon: '/statics/_demo/time-closing_early.svg'
        },
        4: {
          label: 'Limited hours',
          icon: '/statics/_demo/time-limited.svg'
        },
        5: {
          label: 'Delayed opening',
          icon: '/statics/_demo/time-delayed_opening.svg'
        },
        6: {
          label: 'Lunch break',
          icon: '/statics/_demo/time-lunch_break.svg'
        }
      }[status]
    },
    confetti_start () {
      this.$confetti.start({
        particles: 20,
        windSpeedMax: 2
      })
      setTimeout(this.confetti_stop, 5000)
    },
    confetti_stop () {
      this.$confetti.stop()
    },
    getEcosystemLabel (l) {
      return `E.${this.ecosystem_id_t}.${l}`
    },
    htmlEntities (str) {
      return String(str).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;')
    },
    scrolled (scroll) {
      if (!scroll) {
        scroll = this.lastScrolledPayload
      }
      if (scroll.direction === 'down' && scroll.position >= 1) {
        document.querySelector('#appHeader').classList.remove('no-shadow')
      } else if (scroll.direction === 'up' && scroll.position <= 10) {
        document.querySelector('#appHeader').classList.add('no-shadow')
      }

      if (scroll.direction === 'down' && scroll.position >= 222) {
        this.buttonShareShow = true
        document.querySelector('#appTitle .title').innerText = this.productName
      } else if (scroll.direction === 'up' && scroll.position <= 333) {
        this.buttonShareShow = false
        document.querySelector('#appTitle .title').innerText = this.$store.state.app.io.name ? this.$t('HELLO_NAME', { name: this.$store.state.app.io.name }) : this.$t('HELLO_FRIEND')
      }

      this.stickyButtonOrderShow = scroll.position > 300
      this.drawAppSubtitle()
      // backup last scrolled data
      this.lastScrolledPayload = scroll
    },
    drawAppSubtitle () {
      if (this.intentions && this.intentions.service) {
        try {
          document.querySelector('#appTitle .q-toolbar-subtitle').innerHTML = [
            '<div>',
            // '<span>', this.$t(`WINGLET.${this.intentions.service}.L`), '</span>',
            '<span>', this.intentions.dropoffs.lastscan.name, '</span>',
            '</div>'
          ].join('')
        } catch (e) {}
      }
    },
    async accountInit () {
      this.accountInitProcessing = true
      this.account.isLoggedIn = false
      console.log('## this.authenticated::')
      console.log(this.authenticated)
      if (this.authenticated === true) {
        this.account.isLoggedIn = true
        this.account.email = this.$store.state.app.io.email
        this.account.phoneNumber = this.$store.state.app.io.phone
        this.account.metadata = {
          publicAddress: this.$store.state.app.io.idx
        }
        this.subscriptionInit()
        return
      }
      this.accountInitProcessing = false
      // if (!this.account.magicLink) {
      //   this.account.magicLink = this.$magic
      // }
      // this.magicRefresh()
    },
    updatePhoneNumber (payload) {
      this.dialogAccountPhoneNumberPayload = payload
      return true
    },
    async accountLogin () {
      let pn = this.dialogAccountPhoneNumberPayload

      if (pn && pn.isValid) {
        this.dialogAccountPhoneNumberProcessing = true
        this.magicHandleLogin(pn.e164)
      }
    },
    async accountLogout () {
      // set LTC (Logout-To-Channel)
      this.dialogAccountPhoneNumberProcessing = true
      localStorage.setItem('ltc', this.uri)
      this.magicHandleLogout(true)
    },
    async magicRefresh () {
      let _t = this
      setTimeout(() => { _t.dialogAccountPhoneNumberProcessing = false }, 500)
      const isLoggedIn = await this.account.magicLink.user.isLoggedIn()
      this.account.isLoggedIn = isLoggedIn
      this.account.email = ''
      this.account.metadata = {}
      if (isLoggedIn) {
        const userMetadata = await this.account.magicLink.user.getMetadata()
        this.account.email = userMetadata.email
        this.account.phoneNumber = userMetadata.phoneNumber
        this.account.metadata = userMetadata
        // init subscription information
        this.subscriptionInit()
      }
      this.accountInitProcessing = false
    },
    async magicHandleLogin (phoneNumber) {
      // await this.account.magicLink.auth.loginWithMagicLink({ email })
      // await this.account.magicLink.auth.loginWithEmailOTP({ email: phoneNumber })
      await this.account.magicLink.auth.loginWithSMS({ phoneNumber })
      await this.magicRefresh()
    },
    async magicHandleLogout (backendLogout) {
      await this.account.magicLink.user.logout()
      await this.magicRefresh()
      if (backendLogout) {
        // force a full logout
        setTimeout(() => {
          this.$router.push('/logout')
        }, 1)
      }
    },
    walletOpen () {
      this.soundPlay('entry_actionsheet')
      let actions = [{
        label: 'Add Funds' + '<p class="q-actionsheet-sublabel font-size-80p">Add $20, $40, $80, or $100</p>',
        avatar: '/statics/_demo/collection.add.svg',
        status: 'add'
      }, {
        label: 'Automate Funds' + '<p class="q-actionsheet-sublabel font-size-80p">Automatically load funds based on rules</p>',
        avatar: '/statics/_demo/collection.add.multitple.svg',
        status: 'automate'
      }, {}, {
        label: 'Manage ' + '<p class="q-actionsheet-sublabel font-size-80p">History, sharing, and payment sources</p>',
        avatar: '/statics/_demo/lifestyle.app.wallet.dollar-square.svg',
        status: 'manage'
      }]
      this.$q.actionSheet({
        title: 'Wallet',
        actions
      }).then(action => {
        if (action.status === 'add') {
          this.walletAdd()
        } else {
          this.soundPlay('tap')
          this.$q.dialog({
            title: 'not available'
          })
        }
      }).catch(() => {
        this.soundPlay('tap')
      })
    },
    walletAdd () {
      this.soundPlay('entry_actionsheet')
      this.$q.actionSheet({
        title: 'Add Funds',
        actions: [{
          label: '$20.00',
          avatar: '/statics/_demo/collection.add.svg',
          value: 20.00
        }, {
          label: '$40.00',
          avatar: '/statics/_demo/collection.add.svg',
          value: 40.00
        }, {
          label: '$80.00',
          avatar: '/statics/_demo/collection.add.svg',
          value: 80.00
        }, {
          label: '$100.00',
          avatar: '/statics/_demo/collection.add.svg',
          value: 100.00
        }]
      }).then(action => {
        if (action.value) {
          this.wallet_amount = parseFloat(this.wallet_amount) + action.value
          this.$q.notify({
            color: 'white',
            textColor: 'value',
            detail: 'Wallet',
            message: nformat(this.wallet_amount, '$0,0.00'),
            position: 'top',
            timeout: 2000
          })
          this.soundPlay('notification')
        }
      }).catch(() => {
        this.soundPlay('tap')
      })
    },
    disableFilters () {
      document.querySelector('html').classList.add('no-filters')
    },
    enableFilters () {
      document.querySelector('html').classList.remove('no-filters')
    },
    itemContainsGroup (item, group) {
      for (let c in this.offerings_computed[item].products[0].contains) {
        let contains = this.offerings_computed[item].products[0].contains[c]
        if (this.offerings.personas.bases[contains.base] && this.offerings.personas.bases[contains.base].group === group) {
          return true
        }
      }
      return false
    },
    processIs (hash) {
      let _hash = hash || document.location.hash
      if (_hash && _hash.length) {
        let instructionSet = _hash.split('#')[1], _is = {
          instruction: instructionSet.substr(0, 2),
          payload: instructionSet.substr(2)
        }
        switch (_is.instruction) {
          case 'rd': {
            // ref:dropoff
            console.log('::_is: ref:DROPOFF #', _is.payload)
            this.$store.state.app.intentions.dropoffs.lastscan = this.dropoffs['d' + _is.payload]
            // show dialog
            setTimeout(() => {
              this.dialogDropoffShow = true
            }, this.ready ? 200 : 1000)
            break
          }
          default: {
            console.log('::_is: <UNKNOWN>')
          }
        }
      }
    },
    wingletDialogOpen () {
      // this.$store.state.app.intentions.dropoff = !this.$store.state.app.intentions.dropoff
      // this.soundLoop('pinging', true)
      setTimeout(() => {
        this.dialogDropoffShow = true
        this.soundPlay('sheet_up')
        // this.soundPlay('pinging')
        this.toolbarShadowOnOverscrollClear()
      }, 1)
    },
    getWebsiteDomain (url) {
      if (!url) return false
      var domain
      // find & remove protocol (http, ftp, etc.) and get domain
      if (url.indexOf('://') > -1) {
        domain = url.split('/')[2]
      } else {
        domain = url.split('/')[0]
      }
      // find & remove port number
      domain = domain.split(':')[0]
      if (domain.indexOf('www.') === 0) {
        domain = domain.replace('www.', '')
      }
      return domain
    },
    dialogQueueOpen () {
      this.dialogQueueShow = true
      this.soundPlay('sheet_up')
      this.toolbarShadowOnOverscrollClear()
    },
    dialogQueueClose () {
      this.dialogQueueShow = false
      this.soundPlay('tap')
    },
    scan () {
      this.dialogDropoffScanShow = true
      this.toolbarShadowOnOverscrollClear()
      setTimeout(() => {
        this.dialogDropoffScanStreamShow = true
      }, 200)
    },
    scanOff () {
      this.dialogDropoffScanStreamShow = false
      setTimeout(() => {
        this.dialogDropoffScanShow = false
        this.toolbarShadowOnOverscrollClear()
      }, 200)
    },
    scanFlashToggle () {},
    scanCameraToggle () {},
    wingletOnDecode (result) {
      let shortlink = this.product.data.shortlink
      console.log(':: wingletOnDecode: product.shortlink: ', shortlink)
      if (result.indexOf(shortlink) === 0 || result.indexOf('https://ltsbtrf.ly/2YkWOo2') === 0) {
        console.log(':: wingletOnDecode: decoded: ', result, ': consider')
        try {
          document.getElementById('wingletDecodeContainer').classList.add('shrink')
        } catch (e) {}
        //
        let hash = result.indexOf('#') >= 0 ? result.split('#')[1] : false
        if (hash) {
          this.processIs(`#${hash}`)
          setTimeout(() => {
            this.dialogDropoffScanShow = false
            this.toolbarShadowOnOverscrollClear()
            try {
              document.getElementById('wingletDecodeContainer').classList.remove('shrink')
            } catch (e) {}
          }, 400)
        }
      } else {
        console.log(':: wingletOnDecode: decoded: ', result, ': ignore')
        setTimeout(() => {
          this.$q.notify({
            detail: 'Ignored',
            color: 'white',
            textColor: 'value',
            message: 'Unrelated Code',
            position: 'top',
            timeout: 2000
          })
          this.soundPlay('notification')
        }, 1)
      }
    },
    async wingletOnInit (promise) {
      try {
        await promise
      } catch (error) {
        // if (error.name === 'NotAllowedError') {
        //   this.error = "ERROR: you need to grant camera access permisson"
        // } else if (error.name === 'NotFoundError') {
        //   this.error = "ERROR: no camera on this device"
        // } else if (error.name === 'NotSupportedError') {
        //   this.error = "ERROR: secure context required (HTTPS, localhost)"
        // } else if (error.name === 'NotReadableError') {
        //   this.error = "ERROR: is the camera already in use?"
        // } else if (error.name === 'OverconstrainedError') {
        //   this.error = "ERROR: installed cameras are not suitable"
        // } else if (error.name === 'StreamApiNotSupportedError') {
        //   this.error = "ERROR: Stream API is not supported in this browser"
        // }
      }
    },
    setService (service, payload) {
      console.log(':: setService ', service, payload)
      this.intentions.service = service
      this.dialogDropoffShow = false
      this.toolbarShadowOnOverscrollClear()
      setTimeout(() => {
        this.drawAppSubtitle()
      }, 100)
    },
    channelDisconnect () {
      // clear timers
      clearInterval(this.channelOnlineTimer)
      clearInterval(this.channelFetchTimer)
      // unsubscribe
      if (this.pn) {
        // this.pn.unsubscribe({
        //   channels: [this.channelUUID()]
        // })
        // console.log(':: Channel: Presense: Unsubscribed')
        this.pn.stop()
        // console.log(':: Channel: Presense: Stopped')
        // console.log(':: Channel: Presense: Disconnected')
      }
      this.pn = null
    },
    channelInit (done) {
      this.pn = null
      if (done) done()
      this.channelConnect()
      this.channelSubscribe()
      this.channelSetState()
      this.channelSeekHistory()
      this.channelFetchUpdater()
      this.channelOnlineVerifier()
    },
    channelFetchUpdater () {
      // only fetch after 2 seconds of sequential multi-requests
      this.channelFetchTimer = setInterval(() => {
        // console.log(`:: Channel: Presense: Checking: Last=${this.channelFetchActiveClientsRequestsLastCount}, Now=${this.channelFetchActiveClientsRequestsCount}`)
        // are the requests count different
        if (this.channelFetchActiveClientsRequestsLastCount < this.channelFetchActiveClientsRequestsCount) {
          this.channelFetchActiveClientsRequestsLastCount = this.channelFetchActiveClientsRequestsCount
          return
        }
        if (this.channelFetchActiveClientsRequestsCount > 0 && this.channelFetchActiveClientsRequestsCount === this.channelFetchActiveClientsRequestsLastCount) {
          this.channelFetchActiveClientsRequestsLastCount = 0
          this.channelFetchActiveClientsRequestsCount = 0
          this.channelFetchActiveClientsRequest()
        }
      }, 2000)
    },
    channelOnlineVerifier () {
      // we need to force a reload
      // if the _current_ user is not in the list of clients
      // check every 1 minute now
      this.channelOnlineTimer = setInterval(() => {
        // console.log(`:: Channel: Presense: AmIOnline?`)
        if (!this.amIOnline) {
          // console.log(`:: Channel: Presense: AmIOnline: NO`)
          // console.log(`:: Channel: Presense: Restart`)
          // refresh the connection
          this.channelDisconnect()
          this.channelInit()
        }
      }, 60 * 1000)
    },
    channelFetchActiveClients () {
      if (this.channelFetchActiveClientsRequestsFirstTime) {
        this.channelFetchActiveClientsRequestsFirstTime = false
        // console.log(`:: Channel: Presense: Requst Trigger: FirstTime (0-delay)`)
        this.channelFetchActiveClientsRequest()
        return
      }
      this.channelFetchActiveClientsRequestsCount++
      // console.log(`:: Channel: Presense: Request Trigger: @${this.channelFetchActiveClientsRequestsCount}`)
    },
    channelFetchActiveClientsRequest () {
      this.channelFetchActiveClientsRequestActive = true
      const randId = this.$guid.generate()
      // console.log(`:: Channel: Presense: HERENOW: Requested #${randId}`)
      this.pn.hereNow({
        channels: [this.channelID],
        includeUUIDs: true,
        includeState: true
      }, (status, response) => {
        // console.log(`:: Channel: Presense: HERENOW: Responded #${randId}`)
        // console.log(':: Channel: Presense: HERENOW: d: ', response)
        // console.log(':: Channel: Presense: --------------------------------------')
        this.clients = response.channels[this.channelID].occupants
        this.channelFetchActiveClientsRequestActive = false
      })
    },
    encodeUUID (uuid) {
      // Remove hyphens and convert to binary
      const hex = uuid.replace(/-/g, '')
      const bin = Buffer.from(hex, 'hex')
      // Convert binary to Base64
      return bin.toString('base64').replace(/=/g, '')
    },
    decodeUUID (uuidEncoded) {
      // Decode from Base64
      const bin = Buffer.from(uuidEncoded, 'base64')
      // Convert binary to hex
      let hex = bin.toString('hex')
      // Re-insert hyphens to get the UUID in standard format
      return hex.substring(0, 8) + '-' + hex.substring(8, 12) + '-' + hex.substring(12, 16) + '-' + hex.substring(16, 20) + '-' + hex.substring(20)
    },
    channelConnect () {
      // update/check URI
      this.pn = new PubNub({
        subscribeKey: 'sub-c-6ef8f7b4-860c-11e9-99de-d6d3b84c4a25',
        publishKey: 'pub-c-4a7e4814-55a0-4e5f-98d7-eba6d8e92cd3',
        uuid: this.uuid,
        ssl: true,
        autoNetworkDetection: true,
        presenceTimeout: 60
      })
      this.pn.addListener({
        message: (m) => {
          if (m.channel.split('.').pop() === 'requests') {
            // this a request UPDATE
            console.log(':: Channel: [main.requests] UPDATE:')
            console.log(m.message)
            return
          }
          console.log(':: Channel: [main] UPDATE:')
          console.log(m.message)

          // update local channel data with what has been updated
          this.product.data.channel = m.message
          this.$set(this.product.data, 'channel', m.message)

          // built-in
          this.datagroups[0].datafields[0].value.option = this.product.data.channel.online
          this.datagroups[0].datafields[1].value.option = this.product.data.channel.orders
          this.datagroups[0].datafields[3].value.option = this.product.data.channel.loyalty_card

          // force set
          this.$set(this.datagroups[0].datafields[0].value, 'option', this.product.data.channel.online)
          this.$set(this.product.data.channel, 'online', this.product.data.channel.online)

          this.$set(this.datagroups[0].datafields[1].value, 'option', this.product.data.channel.orders)
          this.$set(this.product.data.channel, 'orders', this.product.data.channel.orders)

          this.$set(this.datagroups[0].datafields[3].value, 'option', this.product.data.channel.loyalty_card)
          this.$set(this.product.data.channel, 'loyalty_card', this.product.data.channel.loyalty_card)

          console.log(':: Channel: [main] DATA.CHANNEL:')
          console.log(this.product.data.channel)
          this.channel = m.message

          // store payload
          this.$store.commit('app/updateProductPayload', 0, this.product.payload)

          if (m.message.component && m.message.component.uuid) {
            // we have component status updates
            console.log('recieved component update!', m.message.component)
            try {
              this.product.data.business.components[this.decodeUUID(m.message.component.uuid)].status = m.message.component.status
            } catch (e) {}
          }
        }
      })
    },
    renderListKey (dg, df, init) {
      return [dg, df].join('_')
    },
    channelSubscribe () {
      this.pn.subscribe({
        channels: [this.channelID, `${this.channelID}.requests`],
        withPresence: true
      })
      // listen for the admin
      this.pn.addListener({
        presence: (e) => {
          // console.log(':: Channel: Presense: Change detected: ', e.action)
          // let's call fetch as long as there are no consequence of calls
          this.channelFetchActiveClients()
        },
        status: (statusEvent) => {
          if (statusEvent.category === 'PNConnectedCategory') {
            // console.log(':: Channel: Presense: Connected')
            this.channelFetchActiveClients()
          }
        }
      })
    },
    channelSeekHistory (done) {
      this.channel = null
      if (done) done()
      if (this.pn) {
        return this.pn.history({ channel: this.channelID, count: 1 }, (status, response) => {
          if (!status.error) {
            setTimeout(() => {
              this.channel = response.messages.length ? response.messages[0].entry : false
              // hijack
              let wait = 0
              switch (this.channel.kitchen_wait_time) {
                default:
                case 0: wait = 0; break
                case 1: wait = 5; break
                case 2: wait = 10; break
                case 3: wait = 15; break
                case 4: wait = 25; break
                case 5: wait = 30; break
              }
              this.guardian.business.stations.kitchen.times.wait = wait
              switch (this.channel.barista_wait_time) {
                default:
                case 0: wait = 0; break
                case 1: wait = 5; break
                case 2: wait = 10; break
                case 3: wait = 15; break
                case 4: wait = 25; break
                case 5: wait = 30; break
              }
              this.guardian.business.stations.barista.times.wait = wait
            }, 1400)
          }
        })
      }
      return false
    },
    channelSetState () {
      // let self = this
      this.pn.setState({
        state: {
          observing: true,
          role: 'client',
          idx: this.$store.state.app.io.idx,
          name: this.$store.state.app.io.name,
          phone: this.$store.state.app.io.phone,
          email: this.$store.state.app.io.email
        },
        uuid: this.uuid,
        channels: [this.channelID]
      }, function (r) {
        console.log(':: channelSetState')
        console.log(':: :: fn()')
        console.log(r)
        // self.channel = false
      })
    },
    toolbarShadowOnOverscrollTarget () {
      let modalTarget = null
      document.querySelectorAll('.modal').forEach((o, i) => {
        if (o.clientHeight !== 0) modalTarget = o
      })
      return modalTarget
    },
    toolbarShadowOnOverscrollClear (timeout = 10) {
      setTimeout(() => {
        try {
          let modalTarget = this.toolbarShadowOnOverscrollTarget()
          if (modalTarget) {
            modalTarget.querySelector('.toolbar-overscroll-shadow').classList.remove('toolbar-overscroll-shadow-show')
            // modalTarget.querySelector('.toolbar-overscroll-controls').classList.remove('toolbar-overscroll-controls-show')
          }
        } catch (e) {
          // console.log(':: toolbarShadowOnOverscrollClear: ', e)
        }
      }, timeout)
    },
    toolbarShadowOnOverscroll (scroll) {
      let modalTarget = this.toolbarShadowOnOverscrollTarget()
      if (modalTarget) {
        if (scroll.direction === 'down' && scroll.position >= 1) {
          modalTarget.querySelector('.toolbar-overscroll-shadow').classList.add('toolbar-overscroll-shadow-show')
        } else if (scroll.direction === 'up' && scroll.position <= 10) {
          modalTarget.querySelector('.toolbar-overscroll-shadow').classList.remove('toolbar-overscroll-shadow-show')
        }
        // multi-controls (if any)
        try {
          if (scroll.direction === 'down' && scroll.position >= 160) {
            modalTarget.querySelector('.toolbar-overscroll-controls').classList.add('toolbar-overscroll-controls-show')
          } else if (scroll.direction === 'up' && scroll.position <= 160) {
            modalTarget.querySelector('.toolbar-overscroll-controls').classList.remove('toolbar-overscroll-controls-show')
          }
        } catch (e) {}
      }
    },
    perspective (card) {
      let ids = card.dataset.index.split('-')
      this.dialogPerspectiveItem = this.datagroups[1 * ids[1]].datafields[1 * ids[3]]
      this.dialogPerspectiveShow = true
      this.toolbarShadowOnOverscrollClear()
      console.log(this.dialogPerspectiveItem)
    },
    cleanURL (url) {
      if (!url) return ''
      return url
        .replace('https://', '')
        .replace('http://', '')
        .replace('www.', '')
        .replace(/\/$/, '')
    },
    shareSheetSupport () {
      // console.log(':: shareSheetSupport: ', !!navigator.share)
      return !!navigator.share
    },
    shareSheet () {
      if (this.shareSheetSupport()) {
        let sharePayload = {
          title: ['Wings', this.productName, this.product.data.business.address.full].join(' · '),
          // text: this.product.data.business.address.full,
          url: this.productFullURI
        }
        navigator.share(sharePayload)
          .then(() => console.log('Successful share'))
          .catch((error) => console.log('Error sharing', error))
      } else {
        console.log('::SHARE: -- error')
      }
    },
    notifyMe () {
      this.$q.dialog({
        title: 'Notify Me',
        message: 'Do you agree to be notified when the service becomes available?',
        ok: this.$t('YES'),
        cancel: this.$t('NO')
      }).then(() => {
        // delete
      }).catch(() => {
        // ignore
      })
    },
    personalize () {
      this.toolbarShadowOnOverscrollClear()
      this.dialogPersonalizeShow = !this.dialogPersonalizeShow
    },
    personalizeList () {
      this.toolbarShadowOnOverscrollClear()
      this.dialogPersonalizeListShow = !this.dialogPersonalizeListShow
    },
    personalizeVoice () {
      this.toolbarShadowOnOverscrollClear()
      this.dialogPersonalizeVoiceShow = !this.dialogPersonalizeVoiceShow
      if (this.dialogPersonalizeVoiceShow) {
        this.soundPlay('sheet_mini_up')
        this.personalizeVoiceStartListening()
      }
    },
    voiceInit () {
      try {
        window.AudioContext = window.AudioContext || window.webkitAudioContext
        navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia
        window.URL = window.URL || window.webkitURL

        this.voice.context = new AudioContext()
        console.log(':: recorder: context: created')
        console.log(':: recorder: @navigator.getUserMedia: ' + (navigator.getUserMedia ? 'available' : 'not present'))
      } catch (e) {
        alert(':: recorder: No web audio support in this browser!')
      }

      if (!this.$q.platform.has.touch && navigator.getUserMedia) {
        console.log(':: recorder: getUserMedia: init')
        navigator.getUserMedia({
          audio: true
        }, (stream) => {
          console.log(':: recorder: stream: init')
          this.voiceRecorderInit(stream)
        }, (e) => {
          console.log(':: recorder: No live audio input: ' + e)
        })
      } else if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        console.log(':: recorder: mediaDevices.getUserMedia: init')
        navigator.mediaDevices.getUserMedia({
          audio: true
        }).then((stream) => {
          console.log(':: recorder: stream: init')
          this.voiceRecorderInit(stream)
        }).catch((e) => {
          console.log(':: recorder: No live audio input: ' + e)
        })
      }
    },
    voiceRecorderInit (stream) {
      // create media stream
      console.log(':: recorder: stream: created')
      const input = this.voice.context.createMediaStreamSource(stream)
      // start recorder
      this.voice.recorder = new Recorder(input)
      console.log(':: recorder: initialized')
      this.dialogPersonalizeVoiceStatus = 1 // 1 - init
      this.voiceRecorderStart()
    },
    voiceRecorderStart () {
      this.voice.recorder.record()
      console.log(':: recorder: started')
      this.dialogPersonalizeVoiceStatus = 2 // 2 - listening
      setTimeout(this.voiceRecorderStop, 3.0 * 1000)
    },
    voiceRecorderStop () {
      console.log(':: recorder: stopped')
      this.voice.recorder.stop()
      this.voiceRecorderProcess()
      this.voiceRecorderClear()
    },
    voiceRecorderClear () {
      this.voice.recorder.clear()
      console.log(':: recorder: cleared')
    },
    voiceRecorderProcess () {
      console.log(':: recorder: processing...')
      this.dialogPersonalizeVoiceStatus = 3 // 3 - processing
      this.voice.recorder.exportWAV((blob) => {
        let file = new window.FileReader()
        file.readAsDataURL(blob)
        file.onload = () => {
          const baseData = file.result
          const base64Data = baseData.replace('data:audio/wav;base64,', '')
          this.voice.payload.audio.content = base64Data
          this.$axios.post(
            `https://speech.googleapis.com/v1/speech:recognize?key=AIzaSyCrx0_HAYQ2NfvLJu3aOZQD93pGEBJn-n4`,
            this.voice.payload
          ).then(response => {
            // console.log(':: recorder: processing: response: ', response)
            try {
              let transcript = response.data.results[0].alternatives[0].transcript
              this.personalizeVoiceShowTranscript(transcript)
              this.personalizeVoiceProcessTranscript(transcript)
            } catch (e) {
              this.dialogPersonalizeVoiceText = '--'
              setTimeout(() => {
                this.dialogPersonalizeVoiceStatus = 4
                this.dialogPersonalizeVoiceShow = false
              }, 100)
            }
          }).catch(error => {
            console.log(':: recorder: processing: error: ', error)
          })
        }
      })
    },
    personalizeVoiceStartListening () {
      this.dialogPersonalizeVoiceStatus = 0 // 0 - idle
      this.dialogPersonalizeVoiceText = ''
      this.voiceInit()
    },
    personalizeVoiceShowTranscript (transcript) {
      this.dialogPersonalizeVoiceStatus = 4 // 4 - processed
      console.log(':: recorder: showing: transcript: ', transcript)
      this.soundPlay('notification')
      this.dialogPersonalizeVoiceText = transcript
    },
    personalizeVoiceProcessTranscript (transcript) {
      console.log(':: recorder: processing: transcript: ', transcript)
      axiosLIO.post('/ai/dit', { transcript }).then((res) => {
        console.log(':: recorder: processed: ', res)
        //
        let intent = null
        try {
          intent = res.data.data.intentions.intentName
        } catch (e) {}

        // intent
        console.log(':: recorder: decoded: intent: ', intent)

        // personalize-*
        if (intent.indexOf('personalize') === 0) {
          let personas = []
          if (res.data.data.intentions.result.parameters.fields && res.data.data.intentions.result.parameters.fields.personas) {
            console.log(':: recorder: decoded: personas: exist: #', res.data.data.intentions.result.parameters.fields.personas.listValue.values.length)
            for (let p in res.data.data.intentions.result.parameters.fields.personas.listValue.values) {
              personas.push(
                res.data.data.intentions.result.parameters.fields.personas.listValue.values[p].stringValue
              )
            }
          }
          console.log(':: recorder: decoded: ', intent, personas)
          if (intent === 'personalize-set') {
            // this.personasClear()
            for (let p in personas) {
              this.guardian.consumer.personas[personas[p]] = { status: 'good' }
            }
          }
          if (intent === 'personalize-avoid') {
            // this.personasClear()
            for (let p in personas) {
              this.guardian.consumer.personas[personas[p]] = { status: 'avoid' }
            }
          }
          if (intent === 'personalize-allergic') {
            // this.personasClear()
            for (let p in personas) {
              this.guardian.consumer.personas[personas[p]] = { status: 'health' }
            }
          }
          if (intent === 'personalize-reset') {
            this.personasClear()
            this.personasDefault()
          }
          if (intent === 'personalize-adjust-add') {
            for (let p in personas) {
              this.guardian.consumer.personas[personas[p]] = { status: 'good' }
            }
          }
          if (intent === 'personalize-adjust-remove') {
            for (let p in personas) {
              this.guardian.consumer.personas[personas[p]] = { status: 'avoid' }
            }
          }
        }

        // item-*
        if (intent === 'item-view') {
          let item = null
          try {
            item = res.data.data.intentions.result.parameters.fields.items.stringValue
          } catch (e) {
            console.log(e)
          }
          let group = null
          for (let g in this.offerings.groups) {
            let _g = this.offerings.groups[g]
            if (_g.list && _g.list.indexOf(item) >= 0) {
              group = g
              break
            }
          }
          console.log(':: recorder: decoded: ', intent, group, item)
          if (group && item) {
            this.processCardSelection(['item', group, item].join('-'))
          }
        }

        // bag-*
        if (intent === 'bag-item-add') {
          // let items = []
          if (res.data.data.intentions.result.parameters.fields && res.data.data.intentions.result.parameters.fields.items) {
            console.log(':: recorder: decoded: items: exist: #', res.data.data.intentions.result.parameters.fields.items.listValue.values.length)
            for (let p in res.data.data.intentions.result.parameters.fields.items.listValue.values) {
              let _item = res.data.data.intentions.result.parameters.fields.items.listValue.values[p].stringValue
              if (this.offerings.items[_item]) {
                this.bag.items.push(this.offerings_computed[_item].products[0])
              }
            }
          }
          this.updateBag()
          this.dialogBagOpen()
        }
        if (intent === 'bag-clear') {
          this.bag.items = []
          this.updateBag()
        }

        // end
        setTimeout(() => {
          this.dialogPersonalizeVoiceShow = false
        }, transcript.split(' ').length * 200)
      }).catch(err => {
        console.log(':: recorder: processing: error: ', err)
        this.dialogPersonalizeVoiceShow = false
      })
    },
    personasDefault () {
      for (let p in this.guardian.business.personas.defaults) {
        this.guardian.consumer.personas[this.guardian.business.personas.defaults[p]] = { status: 'good' }
      }
    },
    personasClear () {
      for (let p in this.guardian.consumer.personas) {
        this.guardian.consumer.personas[p] = { status: null }
      }
    },
    personaRemove (persona) {
      console.log(':: personaRemove :', persona)
      this.guardian.consumer.personas[persona] = { status: null }
    },
    personaEdit (persona, payload) {
      this.soundPlay('entry_actionsheet')
      let imgIndicator = '<img class="q-actionsheet-indicator float-right" src="/statics/_demo/checkmark_green.svg"/>'
      let actions = [{
        label: 'Want ' + (payload.status === 'good' ? imgIndicator : '') + '<p class="q-actionsheet-sublabel font-size-80p">Interested</p>',
        avatar: '/statics/_demo/heart_educate.svg',
        status: 'good'
      }, {}, {
        label: 'Avoid ' + (payload.status === 'avoid' ? imgIndicator : '') + '<p class="q-actionsheet-sublabel font-size-80p">Not interested</p>',
        avatar: '/statics/_demo/nosign_attention.svg',
        status: 'avoid'
      }, {
        label: 'Health Condition' + (payload.status === 'health' ? imgIndicator : '') + '<p class="q-actionsheet-sublabel font-size-80p">Allergic or sensitive</p>',
        avatar: '/statics/_demo/avoid_protect.svg',
        status: 'health'
      }]
      if (payload.status !== null) {
        actions.push({})
        actions.push({
          label: 'Remove',
          status: null
        })
      }
      this.$q.actionSheet({ title: persona, actions }).then(action => {
        if (this.guardian.consumer.personas[persona].status === action.status) {
          this.soundPlay('tap_disabled')
        } else if (action.status === null) {
          this.soundPlay('entry_scrub')
        } else {
          this.soundPlay('sheet_drop')
        }
        // console.log(persona, action.status)
        // console.log(':: before :', this.guardian.consumer.personas[persona])
        this.guardian.consumer.personas[persona] = {
          status: action.status
        }
        // console.log(':: after :', this.guardian.consumer.personas[persona])
      }).catch(() => {
        this.soundPlay('tap')
      })
    },
    send () {
      this.dialogProductSendShow = true
      this.soundPlay('tap')
      // addToBag(); dialogProductShow = false; soundPlay('tap')
    },
    // celebrate getting stamped
    celebrateLoyaltyStamp () {
      let stamps = this.account.user_payload.modules.loyalty_card.stamps
      let congratulatoryWordList = [
        'Bravo',
        'Well done',
        'Hooray',
        'Great job',
        'Way to go',
        'Yay',
        'Hurray',
        'Marvelous',
        'Excellent',
        'Keep up the good work'
      ]
      // let congratulatoryStatements = [
      //   'You\'re only a %s steps away from earning a free coffee!',
      //   'Keep going! A free coffee is just %s stamps away.',
      //   'Just a bit further and you\'ll have earned a free coffee for your efforts.',
      //   'A free coffee is almost within your grasp - keep going!',
      //   'You\'re almost there - just %s more stamps and you\'ll get a free coffee.'
      // ]
      let congratulatoryWord = congratulatoryWordList[Math.floor(Math.random() * congratulatoryWordList.length)]
      // let congratulatoryStatement = congratulatoryStatements[Math.floor(Math.random() * congratulatoryStatements.length)]
      this.confetti_stop()
      setTimeout(this.confetti_start, 200)
      this.$q.notify({
        color: 'white',
        textColor: 'value',
        detail: 'Loyalty Reward',
        message: (stamps === 0) ? 'Congratulations on your reward!' : `${congratulatoryWord}!`,
        position: 'top',
        icon: 'ion-star'
        // icon: 'ion-checkmark-circle'
        // timeout: 3000,
      })
      // if (stamps === 0) {
      //   this.$q.notify({
      //     color: 'white',
      //     textColor: 'value',
      //     detail: 'Loyalty Reward',
      //     message: 'Enjoy a new card!',
      //     position: 'center',
      //     icon: 'ion-checkmark-circle'
      //     // timeout: 3000,
      //   })
      // } else {
      //   setTimeout(() => {
      //     this.$q.dialog({
      //       title: `${congratulatoryWord}!`,
      //       message: congratulatoryStatement.replace('%s', 10 - stamps),
      //       color: 'primary',
      //       ok: this.$t('OK')
      //     })
      //   }, 400)
      // }
      this.soundPlay('notification')
    },
    clearLoyaltyRequest () {
      const messageRequest = {
        user: {
          publicAddress: this.account.metadata.publicAddress
        },
        action: 'clear',
        item: 'loyalty_card'
      }
      // connect to PN
      this.pn.publish({
        channel: `${this.channelID}.requests`,
        sendByPost: true,
        storeInHistory: true,
        message: messageRequest
      }, (status, response) => {
        if (status.error) {
          console.log(status)
          this.endLoyaltyRequest()
        } else {
          console.log('message Published w/ timetoken', response.timetoken)
          console.log(response)
        }
      })
    },
    sendLoyaltyRequest () {
      console.log(':: sendLoyaltyRequest')
      if (!this.dialogLoyaltyShow) {
        this.toolbarShadowOnOverscrollClear()
        setTimeout(() => { this.soundPlay('sheet_up') }, 1)
      }
      this.dialogLoyaltyShowTimer = this.dialogLoyaltyShowTimerMax
      this.dialogLoyaltyShow = true
      this.dialogLoyaltyShowUID = this.$guid.generate()
      this.dialogLoyaltyShowPin = Math.floor(Math.random() * 9000) + 1000
      // only start the timer if the admin is online
      // if (!this.isConciergeOnline) {
      //   // check back in 1 second
      //   setTimeout(() => {
      //     this.sendLoyaltyRequest()
      //   }, 1000)
      //   return
      // }
      // start the timer
      let _this = this
      this.dialogLoyaltyShowTimex = setInterval(() => {
        // check if we get stamped
        console.log(':: loyaltyCheck')
        for (let r in this.requests_user) {
          let req = this.requests_user[r]
          if (req.item === 'loyalty_card' && req.status === 'completed') {
            if (req.info.uid === this.dialogLoyaltyShowUID) {
              // update stamps
              if (req.info.stamps >= this.product.data.business.loyalty.stamps + 1) {
                _this.account.user_payload.modules.loyalty_card.number++
                _this.account.user_payload.modules.loyalty_card.stamps = 0
              } else {
                _this.account.user_payload.modules.loyalty_card.stamps = req.info.stamps
              }
              // update carryover if changed
              let carriedOver = req.info.carryover !== _this.account.user_payload.modules.loyalty_card.carryover
              if (carriedOver) {
                _this.account.user_payload.modules.loyalty_card.carryover = req.info.carryover
                // notify carryover message
                // this.$q.notify({
                //   color: 'white',
                //   textColor: 'value',
                //   detail: 'Loyalty Reward',
                //   message: 'Your free drink is carried over to your new card.',
                //   position: 'center',
                //   icon: 'ion-star'
                // })
              }
              _this.account.user_payload.modules.loyalty_card.updated = +new Date()
              _this.endLoyaltyRequest(true)
              // update backend
              _this.updateUserPayload()
              return
            }
          }
        }
        // countdown
        this.dialogLoyaltyShowTimer = this.dialogLoyaltyShowTimer - 1
        this.dialogLoyaltyShowTimerPercentage = 100 - (this.dialogLoyaltyShowTimer / this.dialogLoyaltyShowTimerMax) * 100
        if (!this.dialogLoyaltyShowTimer) {
          this.endLoyaltyRequest()
        }
      }, 1000)
      // send the request to the controller (assuming it's on)
      console.log('==== LOYALTY_REQUEST: account')
      console.log(this.account)
      let _name = this.$store.state.app.io.name ? this.$store.state.app.io.name : false
      let _email = this.$store.state.app.io.email ? this.$store.state.app.io.email : false
      let _phone = this.$store.state.app.io.phone ? this.$store.state.app.io.phone.slice(-4) : false
      let reqName = [_name ? _name : (_phone ? _phone : _email), this.dialogLoyaltyShowPin].join(' ')
      const messageRequest = {
        user: {
          publicAddress: this.account.metadata.publicAddress
        },
        info: {
          uid: this.dialogLoyaltyShowUID,
          pin: this.dialogLoyaltyShowPin,
          name: reqName,
          station: 'reward',
          stamps: this.account.user_payload.modules.loyalty_card.stamps,
          carryover: this.account.user_payload.modules.loyalty_card.carryover
        },
        item: 'loyalty_card'
      }
      console.log('==== LOYALTY_REQUEST: messageRequest')
      console.log('====', messageRequest)
      // connect to PN
      this.pn.publish({
        channel: `${this.channelID}.requests`,
        sendByPost: true,
        storeInHistory: false,
        message: messageRequest
      }, (status, response) => {
        if (status.error) {
          console.log(status)
          this.endLoyaltyRequest()
        } else {
          console.log('message Published w/ timetoken', response.timetoken)
          console.log(response)
        }
      })
    },
    endLoyaltyRequest (completed) {
      console.log(':: endLoyaltyRequest')
      clearInterval(this.dialogLoyaltyShowTimex)
      this.dialogLoyaltyShowTimerPercentage = 100
      this.dialogLoyaltyShow = false
      console.log(':: endLoyaltyRequest: clearRequest')
      this.clearLoyaltyRequest()
      if (completed) {
        console.log(':: endLoyaltyRequest: completed')
        this.celebrateLoyaltyStamp()
      }
    },
    sendRequest () {
      this.dialogProductSendSending = true
      this.soundPlay('tap')
      const messageRequest = {
        user: {
          publicAddress: this.account.metadata.publicAddress
        },
        info: {
          name: this.offerings_computed[this.dialogProductItem].offer,
          station: this.offerings.items[this.offerings_computed[this.dialogProductItem].offer].station
        },
        item: this.offerings_computed[this.dialogProductItem].products[0]
      }
      console.log('====', messageRequest)
      // connect to PN
      this.pn.publish({
        channel: `${this.channelID}.requests`,
        sendByPost: true,
        storeInHistory: true,
        message: messageRequest
      }, (status, response) => {
        if (status.error) {
          console.log(status)
          this.sendRequestCleanup()
        } else {
          console.log('message Published w/ timetoken', response.timetoken)
          console.log(response)
          this.sendRequestCleanup()
          this.sendRequestUpdateWallet()
        }
      })
    },
    sendRequestCleanup () {
      setTimeout(() => {
        this.dialogProductSendSending = false
        this.dialogProductSendShow = false
        this.dialogProductShow = false
      }, 800)
    },
    sendRequestUpdateWallet () {
      setTimeout(() => {
        let amount = {
          current: this.wallet_amount,
          diff: this.price_total(this.offerings_computed[this.dialogProductItem].products[0].price)
        }
        amount.final = amount.current - amount.diff
        this.wallet_amount = amount.final
        this.$q.notify({
          color: 'white',
          textColor: 'value',
          detail: 'Wallet',
          message: nformat(amount.final, '$0,0.00'),
          position: 'top',
          timeout: 2000
        })
        this.soundPlay('notification')
      }, 800)
    },
    price_total (price) {
      const tax = 6 / 100
      return price + (price * tax)
    },
    scrollToRef (ref) {
      const { getScrollTarget, setScrollPosition } = scroll
      const el = this.$refs[ref].$el
      let target = getScrollTarget(el)
      let offset = el.offsetTop - 100
      let duration = 600
      setScrollPosition(target, offset, duration)
    },
    addToBag () {
      let item = this.dialogProductItem
      let itemPayload = {
        item,
        payload: this.offerings_computed[item].products[0]
      }
      this.$store.state.app.bag.items.push(itemPayload)
      this.updateBag()
    },
    updateBag () {
      let total = 0
      this.$store.state.app.bag.items.forEach((i) => {
        total += i.payload.price
      })
      this.$store.state.app.bag.total = total
      // call scrolled() to show sticky buttons (if needed)
      // this is based on previous scrolled state
      this.scrolled()
      this.bagUpdated = true
      setTimeout(() => {
        this.bagUpdated = false
      }, 1000)
    },
    clearBag () {
      this.$q.dialog({
        title: 'empty your bag?',
        color: 'protect',
        ok: this.$t('YES')
        // cancel: this.$t('NO')
      }).then(() => {
        this.bag.items = []
        this.updateBag()
        this.dialogBagClose()
      })
    },
    share () {
      this.toolbarShadowOnOverscrollClear()
      this.dialogShareShow = !this.dialogShareShow
    },
    about () {
      this.toolbarShadowOnOverscrollClear()
      this.dialogAboutShow = !this.dialogAboutShow
    },
    qrcode () {
      this.toolbarShadowOnOverscrollClear()
      this.dialogQRCodeShow = !this.dialogQRCodeShow
    },
    datagroupData (g, f) {
      let datagroups = Wings.datagroups(this)
      let groupIndex = Wings.DGID[g.toUpperCase()]
      let fieldIndex = Wings.DGID[[g.toUpperCase(), f.toUpperCase()].join('_')]
      let path = datagroups[groupIndex].datafields[fieldIndex]
      return path
    },
    datagroupDataValue (g, f, option, sh = false) {
      let labelOption = ['label', sh ? '_sh' : ''].join('')
      return this.datagroupData(g, f).value.options[option][labelOption]
    },
    datafieldsInit () {
      this.datagroups.forEach((g, i) => {
        g.datafields.forEach((f, j) => {
          // initialize values
          if (f.valueType) {
            if (f.valueType.name === 'Boolean') {
              this.datagroups[i].datafields[j].value = {
                option: 0,
                options: [{
                  bTrue: true,
                  label: this.$t('YES')
                }, {
                  bTrue: false,
                  label: this.$t('NO')
                }]
              }
            }
          }
          // initialize option
          if (typeof this.product.data.channel[f.id] === 'undefined') {
            this.product.data.channel[f.id] = this.datagroups[i].datafields[j].value.option || 0
          } else {
            this.datagroups[i].datafields[j].value.option = this.product.data.channel[f.id]
          }
          // create signals
          this.datagroups[i].datafields[j].signal = {
            // create update method
            update: (option) => {
              this.product.data.channel[f.id] = option
              // this.channelPublish()
              // this.mutateProduct(this.product.id, this.product.data, `SIGNAL.${f.id}: ${this.product.data.channel[f.id]}`, () => {})
            },
            updateAll: (ch) => {
              this.product.data.channel = ch
            },
            // create descriptor
            bCheck: () => {
              let bTrue = this.datagroups[i].datafields[j].value.options[this.datagroups[i].datafields[j].value.option || 0].bTrue
              return (bTrue === true || bTrue === false) ? bTrue : null
            },
            descriptor: () => {
              return this.datagroups[i].datafields[j].value.options[this.datagroups[i].datafields[j].value.option || 0].label
            }
          }
        })
      })
    },
    manifestInit () {
      console.log(':: manifestInit()')
      let manifestLink = document.querySelector('link[rel="manifest"]')
      let manifestCfg = {
        // id: location.pathname,
        name: this.__qMeta.title,
        short_name: this.__qMeta.title,
        description: this.__qMeta.description.content,
        background_color: '#ffffff',
        theme_color: '#000000',
        start_url: document.location.origin, // this.productFullURI,
        display: 'fullscreen',
        manifestVersion: +new Date(),
        icons: []
      }
      console.log(':: manifestInit():Cfg = ', manifestCfg)
      console.log(this.productIcon)
      if (this.productIcon) {
        manifestCfg.icons = [{
          // src: this.productIcon,
          // src: 'https://beta.letsbutterfly.app/statics/icons/ms-icon-144x144.png',
          // sizes: '144x144',
          src: 'https://beta.letsbutterfly.app/statics/icons/icon-512x512.png',
          sizes: '512x512',
          type: 'image/png'
        }]
      }
      if (manifestLink) {
        const manifestString = JSON.stringify(manifestCfg)
        const blob = new Blob([manifestString], { type: 'application/javascript' })
        const manifestURL = URL.createObjectURL(blob)
        // update manifest link
        document.querySelector('link[rel="manifest"]').setAttribute('href', manifestURL)
        if (manifestCfg.icons && manifestCfg.icons.length) {
          // update apple-touch-icon (ios)
          document.querySelectorAll('link[rel="apple-touch-icon"]').forEach((domLink) => {
            console.log(':: updated apple-touch-icon @ ', domLink)
            // domLink.setAttribute('href', this.productIcon)
            // domLink.setAttribute('sizes', '144x144')
          })
        }
      }
    },
    dialogItemAdjustFit (percentage = 80) {
      let innerHeight = window.innerHeight
      let dialogItem = document.getElementById('dialogItem')
      if (dialogItem !== null) {
        let dialogItemContent = document.querySelector('#dialogItem .modal-content')
        try {
          dialogItemContent.style.setProperty('height', `${(innerHeight * percentage) / 100}px`, 'important')
        } catch (err) {}
      }
    },
    call (number) {
      document.location = 'tel:' + this.cleanPhoneNumber(number)
    },
    cleanPhoneNumber (number) {
      return number.replace(/ /g, '').replace(/\(/g, '').replace(/\)/g, '').replace(/-/g, '')
    },
    processCardSelection (card) {
      if (typeof card !== 'object') {
        let id = card
        card = this.$refs[`product-card-${id}`].$el || this.$refs[`product-card-${id}`][0].$el
      }
      // grab cardDataObject
      let cardIXs = card.dataset.index.split('-')
      if (cardIXs[0] === 'item') {
        this.dialogProductItem = cardIXs.pop()
        this.dialogProductGroup = cardIXs.pop()
        this.dialogProductShow = true
        this.toolbarShadowOnOverscrollClear()
        setTimeout(() => {
          this.soundPlay('sheet_up')
        }, 1)
      } else {
        let cardObj = this.datagroups[1 * cardIXs[1]].datafields[1 * cardIXs[3]]
        let cardType = cardObj.type
        if (cardType === 'link') {
          openURL(cardObj.indicates.aux().value)
        } else if (cardType === 'phone') {
          this.call(cardObj.indicates.aux().text)
        } else {
          this.perspective(card)
        }
      }
      setTimeout(() => {
        // reset shadow scroll
        // document.querySelector('#itemEditHeader').classList.remove('shadow-overscroll-show')
        // adjust height per viewport
        this.dialogItemAdjustFit()
      }, 10)
    },
    setCardIntent (obj, id, cb) {
      let card = this.$refs[`product-card-${id}`].$el || this.$refs[`product-card-${id}`][0].$el
      // ignore intent if card is disabled
      if (card.attributes.disabled) return
      // handle card
      if (obj.isFirst) {
        card.classList.add('intent')
      } else if (obj.isFinal) {
        if (card.classList.contains('intent')) {
          card.classList.remove('intent')
          if (Math.abs(obj.offset.x) < 50 && Math.abs(obj.offset.y) < 50) {
            // call handler
            setTimeout(function () {
              (cb)(card)
            }, 1)
          }
        }
      } else {
        if (card.classList.contains('intent')) {
          if (Math.abs(obj.offset.x) > 50 || Math.abs(obj.offset.y) > 50) {
            card.classList.remove('intent')
          }
        }
      }
    }
  }
}
</script>
